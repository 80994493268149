<template>
  <FrequencyDistributionChart title="Machine Speed" :averageName="averageName" :maxValue="5000" :minValue="0"
    :indexFactor="1" :frequencies="frequencies" :average="roundedAverage" :quartiles="formattedQuartiles"
    :stepSize="1000" format="numeric" />
</template>

<script>
import FrequencyDistributionChart from '@/components/Reporting/FrequencyDistributionChart'

export default {
  components: {
    FrequencyDistributionChart
  },
  props: {
    average: {
      type: Number,
      required: false
    },
    averageName: {
      type: String,
      required: true
    },
    quartiles: {
      tye: Object,
      required: false
    },
    frequencies: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    roundedAverage() {
      if (!this.average) {
        return this.average
      }

      const roundedAverage = this.average.toFixed(0)

      return Number(roundedAverage)
    },
    formattedQuartiles() {
      if (!this.quartiles) {
        return this.quartiles
      }
      
      const roundedQuartiles = this.quartiles.map(q => q.toFixed(0))

      return roundedQuartiles
    }
  }
}

</script>