import ApiService from './apiService';

export default class ImportInfoService extends ApiService {
  constructor() {
    super()
  }

  async getImports(locationId = null, machineId = null, top = 10, skip = 0) {
    let url = `${this.baseUrl}/admin/imports`

    if (locationId) {
      url += `/${locationId}`

      if (machineId) {
        url += `/${machineId}`
      }
    }

    url += `?top=${top}&skip=${skip}`

    const response = await this.axios.get(url, await this.getStandardRequestConfig())

    return response.data
  }

  async deleteImport(importId) {
    await this.axios.delete(`${this.baseUrl}/admin/imports/${importId}`, await this.getStandardRequestConfig())
  }
}