import ApiService from './apiService';

let instance

export function getInstance() {
  if (!instance) {
    instance = new PaperGradeService()
  }

  return instance
}

export default class PaperGradeService extends ApiService {
  constructor() {
    super()
  }

  async getPaperGrades() {
    const response = await this.axios.get(`${this.baseUrl}/paper-grades`, await this.getStandardRequestConfig())

    return response.data.paperGrades
  }

  async addPaperGrade(name, description) {
    const paperGrades = {
      name: name,
      description: description
    }

    await this.axios.post(`${this.baseUrl}/paper-grades`, paperGrades, await this.getStandardRequestConfig())
  }

  async deletePaperGrade(id) {
    await this.axios.delete(`${this.baseUrl}/paper-grades/${id}`, await this.getStandardRequestConfig())
  }

  async updatePaperGrade(id, name, description, aliases) {
    const paperGrades = {
      name: name,
      description: description,
      aliases: aliases
    }

    await this.axios.patch(`${this.baseUrl}/paper-grades/${id}`, paperGrades, await this.getStandardRequestConfig())
  }
}