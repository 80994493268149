<template>
  <v-data-table
    :headers="headers"
    :items="spreadsheetData"
    :items-per-page="10"
    dense
    :item-class="getRowClass"
    :hide-default-header="mode === 'columnPicker'"
  >
    <template v-slot:header="{ items }" v-if="mode === 'columnPicker'">
      <thead>
        <tr>
          <th v-for="header in headers" v-bind:key="header.value" :class="header.value === 'index' 
            ? 'spreadsheet-index-column' 
            : highlightedColumns.includes(header.value) ? 'primary' : ''"
          >
            {{header.text}}
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:body="{ items }" v-if="mode === 'columnPicker'">
      <tbody>
        <tr v-for="(row, rowIndex) in items" v-bind:key="row.index">
          <td class="spreadsheet-index-column">{{row.index}}</td>
          <td v-for="(item, columnIndex) in row" v-if="columnIndex !== 'index'" v-bind:key="columnIndex" :class="highlightedColumns.includes(columnIndex) ? 'primary' : ''">{{item}}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table> 
</template>

<script>
import { mapStores } from 'pinia'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

export default {
  name: 'SpreadsheetViewer',
  props: {
    mode: {
      type: String,
      required: true,
      validator(value) {
        return ['rowPicker', 'columnPicker', 'verify'].includes(value)
      }
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore),
    mappedColumns() {
      let columns = []
      if (this.dataImportWorkflowStore.headboxConsistencyColumn.columnName !== null) {
        columns.push({ name: 'headboxConsistency', mappedValue: this.dataImportWorkflowStore.headboxConsistencyColumn.columnName })
      }
      if (this.dataImportWorkflowStore.traySolidsColumn.columnName !== null) {
        columns.push({ name: 'traySolids', mappedValue: this.dataImportWorkflowStore.traySolidsColumn.columnName })
      }
      if (this.dataImportWorkflowStore.firstPassRetentionColumn.columnName !== null) {
        columns.push({ name: 'firstPassRetention', mappedValue: this.dataImportWorkflowStore.firstPassRetentionColumn.columnName })
      }
      if (this.dataImportWorkflowStore.gradeColumn.columnName !== null) {
        columns.push({ name: 'grade', mappedValue: this.dataImportWorkflowStore.gradeColumn.columnName })
      }
      if (this.dataImportWorkflowStore.machineSpeedColumn.columnName !== null) {
        columns.push({ name: 'machineSpeed', mappedValue: this.dataImportWorkflowStore.machineSpeedColumn.columnName })
      }
      if (this.dataImportWorkflowStore.conditionWeightColumn.columnName !== null) {
        columns.push({ name: 'conditionWeight', mappedValue: this.dataImportWorkflowStore.conditionWeightColumn.columnName })
      }
      if (this.dataImportWorkflowStore.lbsTonPolymerColumn.columnName !== null) {
        columns.push({ name: 'lbsTonPolymer', mappedValue: this.dataImportWorkflowStore.lbsTonPolymerColumn.columnName })
      }
      if (this.dataImportWorkflowStore.dateColumn.columnName !== null) {
        columns.push({ name: 'date', mappedValue: this.dataImportWorkflowStore.dateColumn.columnName })
      }

      return columns
    },
    headers() {
      if (this.dataImportWorkflowStore.worksheet.length === 0) {
        return []
      }

      if (this.mode === 'verify') {
        let headers = []

        for (const column of this.mappedColumns) {
          headers.push({
            text: column.name.toUpperCase(),
            value: column.mappedValue,
            sortable: false,
            width: 70
          })
        }

        return headers
      }

      let headers = [
        {
          text: 'Row #',
          value: 'index',
          sortable: false,
          width: 70,
          class: 'spreadsheet-index-column',
          cellClass: 'spreadsheet-index-column'
        }
      ]

      for (const header of Object.keys(this.dataImportWorkflowStore.worksheet[0])) {
        let headerName = header
        let headerText = header
        let columnClass = ''

        if (this.mode !== 'rowPicker' && this.dataImportWorkflowStore.headerRow) {
          headerText = this.dataImportWorkflowStore.worksheet[this.dataImportWorkflowStore.headerRow - 1][headerName]
        }

        headers.push({
          text: headerText,
          value: headerName,
          sortable: false,
          class: columnClass,
          cellClass: columnClass
        })
      }

      return headers
    },
    spreadsheetData() {
      let indexedData = this.dataImportWorkflowStore.worksheet.map((items, index) => ({
        ...items,
        index: index + 1
      }))

      if (this.mode === 'rowPicker') {
        return indexedData
      }

      const data =  indexedData.splice(this.dataImportWorkflowStore.startRow - 1, this.dataImportWorkflowStore.endRow - 1)

      return data
    },
    highlightedColumns() {
      if (this.mode !== 'columnPicker' || !this.mappedColumns) {
        return []
      }

      return Object.values(this.mappedColumns).map(c => c.name)
    }
  },
  methods: {
    getRowClass(item) {
      if (this.mode !== 'rowPicker') {
        return ''
      }

      if (this.dataImportWorkflowStore.headerRow) {
        if (item.index == this.dataImportWorkflowStore.headerRow) {
          return 'warning'
        }
      }

      if (
        this.dataImportWorkflowStore.startRow &&
        this.dataImportWorkflowStore.endRow &&
        item.index >= this.dataImportWorkflowStore.startRow &&
        item.index <= this.dataImportWorkflowStore.endRow
      ) {
        return 'primary'
      }

      return ''
    }
  },
}
</script>

<style scoped>
.spreadsheet-index-column {
  border-right: solid thin lightgray;
}
</style>