<template>
  <v-app id="app">
    <Header />
    <v-main v-if="!$auth.loading">
      <Navigation />
      <router-view />
    </v-main>
    <v-progress-circular
      v-else
      indeterminate
      :size="200"
      :width="10"
      color="primary"
      class="center-absolute"
    ></v-progress-circular>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Navigation from './components/Navigation.vue'

export default {
  components: {
    Header,
    Navigation
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Interstate', Helvetica, sans-serif;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
