<template>
  <div id="machine-selector">
    <div v-if="isAdmin">
      <h2>Select Location</h2>
      <v-select 
        @change="onLocationSelected"
        :value="dataImportWorkflowStore.locationId"
        class="ply-selector"
        :items="locations" 
        item-text="name"
        item-value="id" 
        outlined 
        dense 
        data-cy="select-input-ply" />
    </div>
    <h2>Select Machine</h2>
    <div v-if="!loading && !this.selectedLocation.deactivated && machines.length > 0">
      <v-radio-group @change="onMachineSelected" :value="dataImportWorkflowStore.machineId">
        <v-radio v-for="machine in machines" :key="machine.id" :label="machine.name" :value="machine.id" />
      </v-radio-group>
      <h2>Select Ply</h2>
      <v-select 
        @change="onPlySelected"
        :value="dataImportWorkflowStore.ply"
        class="ply-selector"
        :items="plies" 
        item-text="text"
        item-value="value" 
        outlined 
        dense 
        data-cy="select-input-ply" />
    </div>
    <v-alert v-else-if="!loading && (machines.length == 0 || this.selectedLocation.deactivated)" color="error" text border="top"
      type="error">
      There are no active machines for your location. Please contact your administrator.
    </v-alert>
    <Loader v-else />
  </div>
</template>

<script>
import { mapStores } from 'pinia'

import config from '@/config'
import { getInstance as getLocationService } from '@/services/locationService'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

import Loader from '@/components/Parts/Loader'

export default {
  name: 'MachineSelector',
  components: {
    Loader
  },
  data() {
    return {
      loading: true,
      locationService: null,
      locations: [],
      selectedMachineId: null,
      plies: [
        {
          text: 'Base',
          value: 'base'
        },
        {
          text: 'Mid',
          value: 'mid'
        },
        {
          text: 'Top',
          value: 'top'
        }
      ],
      selectedPly: 'base'
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore),
    roles() {
      const roles = this.$auth.user[`${config.auth0.customClaimNamespace}/roles`]

      if (!roles) {
        return []
      }

      return roles
    },
    isAdmin() {
      return this.roles.includes('Admin')
    },
    selectedLocation() {
      return this.locations.find(l => l.id === this.dataImportWorkflowStore.locationId)
    },
    machines() {
      return this.selectedLocation.machines
    }
  },
  methods: {
    onLocationSelected(locationId) {
      this.dataImportWorkflowStore.setLocation(locationId)
    },
    onMachineSelected(machineId) {
      this.dataImportWorkflowStore.setMachine(machineId)
    },
    onPlySelected(ply) {
      this.dataImportWorkflowStore.setPly(ply)
    }
  },
  async created() {
    this.loading = true
    if (!this.locationService) {
      this.locationService = getLocationService()
    }

    const currentLocation = await this.locationService.getUserLocation()

    if (this.isAdmin) {
      this.locations = await this.locationService.getLocations()
    } else {
      this.locations = [currentLocation]
    }

    this.dataImportWorkflowStore.setLocation(currentLocation.id)

    this.loading = false
  }
}
</script>

<style scoped>
.ply-selector {
  width: 250px;
}
</style>
