<template>
  <v-container fluid id="user-management">
    <v-row>
      <v-col>
        <v-card elevation="1" outlined>
          <v-card-title>Inactive Users</v-card-title>
          <v-data-table
            :headers="headers"
            :items="inactiveUsers"
            :items-per-page="10"
            :loading="loadingInactiveUsers"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="text-decoration-underline primary--text"
                plain
                x-small
                @click="activateUser(item.id)"
                >Activate</v-btn
              >
              <span>|</span>
              <v-btn
                class="text-decoration-underline primary--text"
                plain
                x-small
                @click="openDialog(item.id)"
                >Edit</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="1" outlined>
          <v-card-title>Active Users</v-card-title>
          <v-data-table
            :headers="headers"
            :items="activeUsers"
            :items-per-page="10"
            :loading="loadingActiveUsers"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="text-decoration-underline primary--text"
                plain
                x-small
                @click="promoteUser(item.id)"
                >Promote to Admin</v-btn
              >
              <span>|</span>
              <v-btn
                class="text-decoration-underline primary--text"
                plain
                x-small
                @click="openDialog(item.id)"
                >Edit</v-btn
              >
              <span>|</span>
              <v-btn
                class="text-decoration-underline error--text"
                plain
                x-small
                @click="deactivateUser(item.id)"
                >Deactivate</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="1" outlined>
          <v-card-title>Admin Users</v-card-title>
          <v-data-table
            :headers="headers"
            :items="adminUsers"
            :items-per-page="10"
            :loading="loadingAdminUsers"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="text-decoration-underline primary--text"
                plain
                x-small
                @click="openDialog(item.id)"
                >Edit</v-btn
              >
              <span>|</span>
              <v-btn
                class="text-decoration-underline error--text"
                plain
                x-small
                @click="deactivateUser(item.id)"
                >Deactivate</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogOpen" max-width="500px">
      <v-card>
        <v-card-title>
          <div class="text-h5">Edit User</div>
        </v-card-title>
        <v-card-text>
          <v-select
            :items="allowedLocations"
            v-model="editedUser.location"
            label="Location"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveUser"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UserService from '@/services/userService'
import LocationService from '@/services/locationService'

let userService = null
let locationService = null

export default {
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Location',
          sortable: true,
          value: 'location.name'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          align: 'end'
        }
      ],
      inactiveUsers: [],
      loadingInactiveUsers: false,
      activeUsers: [],
      loadingActiveUsers: false,
      adminUsers: [],
      loadingAdminUsers: false,
      dialogOpen: false,
      editedUser: {},
      allowedLocations: []
    }
  },
  computed: {
    users() {
      return this.inactiveUsers.concat(this.activeUsers, this.adminUsers)
    }
  },
  watch: {
    dialogOpen(val) {
      if (!val) {
        this.clearEditedUser()
      }
    }
  },
  async created() {
    userService = new UserService(this.$auth)
    locationService = new LocationService(this.$auth)
  },
  methods: {
    async getInactiveUsers() {
      this.loadingInactiveUsers = true
      this.inactiveUsers = await userService.getInactiveUsers()
      this.loadingInactiveUsers = false
    },
    async getWorkers() {
      this.loadingActiveUsers = true
      this.activeUsers = await userService.getWorkers()
      this.loadingActiveUsers = false
    },
    async getAdmins() {
      this.loadingAdminUsers = true
      this.adminUsers = await userService.getAdmins()
      this.loadingAdminUsers = false
    },
    async activateUser(userId) {
      this.loadingActiveUsers = true
      this.loadingInactiveUsers = true

      await userService.activateUser(userId)

      await Promise.all([this.getInactiveUsers(), this.getWorkers()])

      this.loadingActiveUsers = false
      this.loadingInactiveUsers = false
    },
    async deactivateUser(userId) {
      this.loadingActiveUsers = true
      this.loadingInactiveUsers = true

      await userService.deactivateUser(userId)

      await Promise.all([
        this.getInactiveUsers(),
        this.getWorkers(),
        this.getAdmins()
      ])

      this.loadingActiveUsers = false
      this.loadingInactiveUsers = false
    },
    async promoteUser(userId) {
      this.loadingActiveUsers = true

      await userService.promoteUser(userId)
      await Promise.all([this.getWorkers(), this.getAdmins()])

      this.loadingActiveUsers = false
    },
    openDialog(id) {
      this.editedUser.id = id
      this.editedUser.location = this.users.find((u) => u.id == id).location
      this.dialogOpen = true
    },
    closeDialog() {
      this.clearEditedUser()
      this.dialogOpen = false
    },
    clearEditedUser() {
      this.editedUser = {}
    },
    setAllLoading(val) {
      this.loadingInactiveUsers = val
      this.loadingActiveUsers = val
      this.loadingAdminUsers = val
    },
    async reloadAllUsers() {
      await Promise.all([
        this.getInactiveUsers(),
        this.getWorkers(),
        this.getAdmins()
      ])
    },
    async saveUser() {
      this.setAllLoading(true)

      await userService.updateLocation(
        this.editedUser.id,
        this.editedUser.location
      )

      await this.reloadAllUsers()

      this.setAllLoading(false)
      this.closeDialog()
    },
    async getAllowedLocations() {
      const locations = await locationService.getLocations()
      this.allowedLocations = locations.map((l) => {
        return { text: l.name, value: l.id }
      })
    }
  },
  async mounted() {
    await Promise.all([
      this.getInactiveUsers(),
      this.getWorkers(),
      this.getAdmins(),
      this.getAllowedLocations()
    ])
  }
}
</script>