<template>
  <v-container>
    <v-row>
      <v-col class="machine-info-header">Machines:</v-col>
    </v-row>
    <v-row>
      <v-col v-for="machine in machines" :key="machine.id">
        <v-card>
          <v-card-title>{{ machine.name }}</v-card-title>
          <v-card-actions>
            <v-btn
              class="primary"
              small
              @click="openDialog('edit', machine.id)"
            >
              Edit
            </v-btn>
            <v-btn
              v-if="machine.deactivated"
              class="primary"
              small
              @click="activateMachine(machine.id)"
            >
              Activate
            </v-btn>
            <v-btn
              v-if="machine.deactivated"
              class="error"
              small
              @click="openDeleteConfirmationDialog(machine.id)"
            >
              Delete
            </v-btn>
            <v-btn
              v-if="!machine.deactivated"
              class="error"
              small
              @click="openDeactivateConfirmationDialog(machine.id)"
            >
              Deactivate
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-btn class="primary" small @click="openDialog('new')">
          Add New Machine
        </v-btn>
        <v-dialog v-model="dialogOpen" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  label="Machine Name"
                  v-model="editedMachine.name"
                ></v-text-field>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveMachine"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDialog" width="500">
      <v-card>
        <v-card-title> Confirm Deletion </v-card-title>
        <v-card-text>
          Are you sure you want to delete this machine? This action cannot be undone.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteMachine"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeactivateDialog" width="500">
      <v-card>
        <v-card-title> Confirm Deletion </v-card-title>
        <v-card-text>
          Are you sure you want to deactivate this machine? This will prevent
          new data from being uploaded to this machine?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeactivateDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deactivateMachine"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LocationService from '@/services/locationService'

let locationService = null

export default {
  props: {
    locationId: {
      type: String,
      required: true
    },
    machines: {
      type: Array,
      required: true
    }
  },
  computed: {
    dialogTitle() {
      return this.dialogMode === 'new' ? 'New Machine' : 'Edit Machine'
    }
  },
  data() {
    return {
      dialogOpen: false,
      dialogMode: 'new',
      editedMachine: {},
      confirmDeleteDialog: false,
      confirmDeactivateDialog: false
    }
  },
  watch: {
    dialogOpen(val) {
      if (!val) {
        this.clearEditedMachine()
      }
    }
  },
  methods: {
    openDialog(mode, id) {
      this.dialogMode = mode

      if (mode === 'edit') {
        this.editedMachine.id = id
        this.editedMachine.name = this.machines.find((m) => m.id == id).name
      }

      this.dialogOpen = true
    },
    closeDialog() {
      this.dialogOpen = false
      this.clearEditedMachine()
    },
    clearEditedMachine() {
      this.editedMachine = {}
    },
    async saveMachine() {
      this.$emit('updating')

      if (this.dialogMode === 'new') {
        await locationService.addMachine(
          this.locationId,
          this.editedMachine.name
        )
      } else if (this.dialogMode === 'edit' && this.editedMachine.id) {
        await locationService.updateMachine(
          this.locationId,
          this.editedMachine.id,
          this.editedMachine.name
        )
      }

      this.$emit('updateComplete')
      this.closeDialog()
    },
    openDeleteConfirmationDialog(id) {
      this.confirmDeleteDialog = true
      this.editedMachine.id = id
    },
    openDeactivateConfirmationDialog(id) {
      this.confirmDeactivateDialog = true
      this.editedMachine.id = id
    },
    async deleteMachine() {
      this.$emit('updating')
      this.confirmDeleteDialog = false

      await locationService.deleteMachine(
        this.locationId,
        this.editedMachine.id
      )

      this.$emit('updateComplete')
    },
    async deactivateMachine() {
      this.$emit('updating')
      this.confirmDeactivateDialog = false

      await locationService.deactivateMachine(this.locationId, this.editedMachine.id)

      this.$emit('updateComplete')
    },
    async activateMachine(id) {
      this.$emit('updating')

      await locationService.activateMachine(this.locationId, id)

      this.$emit('updateComplete')
    }
  },
  async created() {
    locationService = new LocationService(this.$auth)
  }
}
</script>