import ApiService from "./apiService"

let instance

export function getInstance() {
  if (!instance) {
    instance = new UserService()
  }

  return instance
}

export default class UserService extends ApiService {
  constructor() {
    super()
  }

  async getCurrentUser() {
    const response = await this.axios.get(`${this.baseUrl}/users/current`, await this.getStandardRequestConfig())
    return response.data
  }

  async createNewUser() {
    await this.axios.post(`${this.baseUrl}/users`, {}, await this.getStandardRequestConfig())
  }

  async getInactiveUsers() {
    const response = await this.axios.get(`${this.baseUrl}/users/default`, await this.getStandardRequestConfig())

    return response.data.users
  }

  async getWorkers() {
    const response = await this.axios.get(`${this.baseUrl}/users/worker`, await this.getStandardRequestConfig())

    return response.data.users
  }

  async getAdmins() {
    const response = await this.axios.get(`${this.baseUrl}/users/admin`, await this.getStandardRequestConfig())

    return response.data.users
  }

  async activateUser(userId) {
    const userUpdate = {
      role: 'worker'
    }

    await this.axios.patch(`${this.baseUrl}/users/${userId}`, userUpdate, await this.getStandardRequestConfig())
  }

  async deactivateUser(userId) {
    const userUpdate = {
      role: 'default'
    }

    await this.axios.patch(`${this.baseUrl}/users/${userId}`, userUpdate, await this.getStandardRequestConfig())
  }

  async promoteUser(userId) {
    const userUpdate = {
      role: 'admin'
    }

    await this.axios.patch(`${this.baseUrl}/users/${userId}`, userUpdate, await this.getStandardRequestConfig())
  }

  async updateLocation(userId, locationId) {
    const userUpdate = {
      locationId: locationId
    }

    await this.axios.patch(`${this.baseUrl}/users/${userId}`, userUpdate, await this.getStandardRequestConfig())
  }
}