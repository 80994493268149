import ApiService from './apiService';

let instance

export function getInstance() {
  if (!instance) {
    instance = new DataService()
  }

  return instance
}

export default class DataService extends ApiService {
  constructor() {
    super()
  }

  async deleteImport(locationId, machineId, importId) {
    await this.axios.delete(`${this.baseUrl}/locations/${locationId}/machines/${machineId}/imports/${importId}`, await this.getStandardRequestConfig())
  }

  async import(
    spreadsheet, 
    locationId, 
    machineId, 
    ply, 
    fileName, 
    worksheetName, 
    startRow, 
    endRow, 
    headerRow, 
    columnMap, 
    gradeMap, 
    lbsTonPolymerConstant) {

    const filteredByStartEnd = spreadsheet.slice(startRow - 1, endRow)

    const filteredByColumn = filteredByStartEnd.map((row) => {
      let newRow = {}

      for (const key of Object.keys(row)) {
        for (const [metricName, columnInfo] of Object.entries(columnMap)) {
          if (columnInfo.name === key) {
            newRow[metricName] = row[key]
            break
          }
        }
      }

      return newRow
    })

    const data = {
      locationId,
      machineId,
      ply,
      fileName,
      worksheetName,
      startRow,
      endRow,
      headerRow,
      data: filteredByColumn,
      gradeMap: Object.keys(gradeMap).map((key) => {
        return {
          alias: key,
          grade: gradeMap[key].name,
          skip: gradeMap[key].skip
        }
      }),
      columnMap,
      lbsTonPolymerConstant
    }

    const response = await this.axios.post(`${this.baseUrl}/import`, data, await this.getStandardRequestConfig())

    return response.data
  }

  async getImportInfoByFilename(locationId, machineId, filename) {
    const response = await this.axios.get(`${this.baseUrl}/import/info?locationId=${locationId}&machineId=${machineId}&filename=${filename}`, await this.getStandardRequestConfig())

    return response.data
  }
}