<template>
  <v-container fluid id="system-management">
    <v-row>
      <v-col>
        <LocationManager />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PaperGradeManager />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocationManager from '@/components/SystemManagement/LocationManager'
import PaperGradeManager from '@/components/SystemManagement/PaperGradeManager'

export default {
  components: {
    LocationManager,
    PaperGradeManager
  }
}
</script>
