<template>
  <v-card>
    <v-card-title>Manage Locations</v-card-title>
    <v-card-text>
      <strong>Note:</strong> Some locations/machines may not be able to be
      deleted if they have imports already associated with them. Please
      deactivate them instead. If a location/machine must be deleted you should
      delete the associated imports first.
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="locations"
      :items-per-page="10"
      :loading="loading"
      show-expand
      :single-expand="false"
      :expanded.sync="expandedLocations"
    >
      <template v-slot:top>
        <v-btn
          small
          plain
          color="primary"
          class="ma-1"
          @click="openDialog('new')"
        >
          Add Location
        </v-btn>
        <v-dialog v-model="dialogOpen" max-width="500px">
          <v-card>
            <v-card-title>
              <div class="text-h5">{{ dialogTitle }}</div>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Location Name"
                v-model="editedLocation.name"
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="saveLocation"> Save </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.deactivated="{ item }">
        {{ item.deactivated ? 'Inactive' : 'Active' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="actions-container border">
          <span>
            <v-btn
              class="text-decoration-underline primary--text"
              plain
              x-small
              @click="openDialog('edit', item.id)"
            >
              Edit
            </v-btn>
          </span>
          <span>|</span>
          <span v-if="item.deactivated">
            <v-btn
              class="text-decoration-underline primary--text"
              plain
              x-small
              @click="activateLocation(item.id)"
            >
              Activate
            </v-btn>
            <span>|</span>
            <v-btn
              class="text-decoration-underline error--text"
              plain
              x-small
              @click="openDeleteConfirmationDialog(item.id)"
            >
              Delete
            </v-btn>
          </span>
          <span v-else>
            <v-btn
              class="text-decoration-underline error--text"
              plain
              x-small
              @click="openDeactivateConfirmationDialog(item.id)"
            >
              Deactivate
            </v-btn>
          </span>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <MachineManager
          v-bind:locationId="item.id"
          v-bind:machines="locations.find((l) => l.id == item.id).machines"
          @updating="loading = true"
          @updateComplete="onMachineUpdateComplete"
        />
      </template>
    </v-data-table>

    <v-dialog v-model="confirmDeleteDialog" width="500">
      <v-card>
        <v-card-title> Confirm Deletion </v-card-title>
        <v-card-text>
          Are you sure you want to delete this location? This will also delete
          any assoicated machines. This action cannot be undone. (Any data
          imported for this location will not be affected)
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteLocation"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeactivateDialog" width="500">
      <v-card>
        <v-card-title> Confirm Deactivation </v-card-title>
        <v-card-text>
          Are you sure you want to deactivate this location? This will prevent
          new data from being uploaded to this location and new users from
          selecting this location on signup.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeactivateDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deactivateLocation"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="error--text"> Error </v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import MachineManager from './MachineManager'
import LocationService from '@/services/locationService'

let locationService = null

export default {
  components: {
    MachineManager
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Active',
          sortable: true,
          value: 'deactivated'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'end',
          value: 'actions'
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ],
      locations: [],
      expandedLocations: [],
      dialogOpen: false,
      dialogMode: 'new',
      editedLocation: {},
      errorDialog: false,
      errorMessage: '',
      confirmDeleteDialog: false,
      confirmDeactivateDialog: false
    }
  },
  computed: {
    dialogTitle() {
      return this.dialogMode === 'new' ? 'New Location' : 'Edit Location'
    }
  },
  watch: {
    dialogOpen(val) {
      if (!val) {
        this.clearEditedLocation()
      }
    },
    errorMessage(val) {
      console.error(val)
    }
  },
  methods: {
    openDialog(mode, id) {
      this.dialogMode = mode

      if (mode === 'edit') {
        this.editedLocation.id = id
        this.editedLocation.name = this.locations.find((l) => l.id == id).name
      }

      this.dialogOpen = true
    },
    closeDialog() {
      this.dialogOpen = false
      this.clearEditedLocation()
    },
    async saveLocation() {
      this.loading = true

      if (this.dialogMode === 'new') {
        await locationService.addLocation(this.editedLocation.name)
      } else if (this.dialogMode === 'edit' && this.editedLocation.id) {
        await locationService.updateLocation(
          this.editedLocation.id,
          this.editedLocation.name
        )
      }

      this.locations = await locationService.getLocations()

      this.loading = false
      this.closeDialog()
    },
    openDeleteConfirmationDialog(id) {
      this.confirmDeleteDialog = true
      this.editedLocation.id = id
    },
    openDeactivateConfirmationDialog(id) {
      this.confirmDeactivateDialog = true
      this.editedLocation.id = id
    },
    async deleteLocation() {
      this.loading = true
      this.confirmDeleteDialog = false

      const result = await locationService.deleteLocation(
        this.editedLocation.id
      )

      if (result.error) {
        this.errorMessage = result.error
        this.errorDialog = true
      } else {
        this.locations = await locationService.getLocations()
      }

      this.loading = false
    },
    async deactivateLocation() {
      this.loading = true
      this.confirmDeactivateDialog = false

      await locationService.deactivateLocation(this.editedLocation.id)
      this.locations = await locationService.getLocations()

      this.loading = false
    },
    async activateLocation(id) {
      this.loading = true

      await locationService.activateLocation(id)
      this.locations = await locationService.getLocations()

      this.loading = false
    },
    clearEditedLocation() {
      this.editedLocation = {}
    },
    async onMachineUpdateComplete() {
      this.locations = await locationService.getLocations()
      this.loading = false
    }
  },
  async created() {
    locationService = new LocationService(this.$auth)

    this.loading = true
    this.locations = await locationService.getLocations()
    this.loading = false
  }
}
</script>

<style scoped>
.actions-container {
  text-align: right;
}
</style>