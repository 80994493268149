export default {
  beforeDraw: function (chart) {
    if (!chart.config.options.elements.drawText) {
      return
    }

    // Get ctx from string
    var ctx = chart.chart.ctx

    // Get options from the center object in options
    var drawTextConfig = chart.config.options.elements.drawText
    var fontStyle = "'Interstate', Helvetica, sans-serif"
    var txt = drawTextConfig.text
    var color = drawTextConfig.color || '#007AC9'
    var fontSize = 75

    // Set font settings to draw it correctly.
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
    ctx.font = `${fontSize}px ${fontStyle}`
    ctx.fillStyle = color

    ctx.fillText(txt, centerX, centerY)
  }
}