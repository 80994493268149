 import ApiService from './apiService';

export default class ReportService extends ApiService{
  constructor() {
    super()
  }

  async getData(gradeId, locationId, machineId, ply) {
    const url = `${this.baseUrl}/reports?gradeId=${gradeId}&locationId=${locationId}&machineId=${machineId}`

    const response = await this.axios.get(url, await this.getStandardRequestConfig())

    let reports = {}
    for (const metric of response.data.metrics) {
      const selectedMachineAverage = metric.machineAverages.filter(a => a.locationId == locationId && a.machineId == machineId && a.ply == ply)[0]

      reports[metric.metric] = {
        name: metric,
        average: selectedMachineAverage && selectedMachineAverage.length != 0 ? selectedMachineAverage.average : null,
        frequencies: metric.frequencyDistribution,
        quartiles: metric.quartiles ? metric.quartiles : [],
        maxValue: metric.frequencyMaxValue,
        minValue: metric.frequencyMinValue,
        indexFactor: metric.frequencyIndexFactor
      }
    }

    return reports
  }

  async exportReport(grade, locationId) {
    const filename = `${grade.name}.xlsx`

    const requestConfig = {
      headers: {
        'Content-Disposition': `attachment; filename=${filename}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${await this.getAccessToken()}`
      },
      responseType: 'arraybuffer'
    }

    const url = `${this.baseUrl}/reports/export?gradeId=${grade.id}&locationId=${locationId}`

    try {
      const response = await this.axios.get(url, requestConfig)

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', filename)
      document.body.appendChild(link)
  
      link.click()
    } catch (e) {
      if (e.response.status == 404) {
        console.warn('Nothing to export.')
      } else {
        throw e
      }
    }  
  }
}