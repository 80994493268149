import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: false,
            light: {
                primary: '#007AC9',
                secondary: '#b0bec5',
                accent: '#58A618',
                error: '#b71c1c',
                warning: '#EEAF00'
            }
        }
    }
});
