<template>
  <div id="data-import">
    <v-container fluid>
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1">
            Select Machine
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 2" step="2">
            Select Spreadsheet
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 3" step="3">
            Select Rows
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 4" step="4">
            Select Columns
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 5" step="5">
            Map Grades
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 6" step="6">
            Verify and Submit
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <MachineSelector />

            <v-btn
              color="accent"
              @click="nextStep"
              :disabled="!machineSelected || !locationSelected"
              data-cy="continue"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <SpreadsheetSelector />

            <v-btn
              color="accent"
              @click="nextStep"
              :disabled="!worksheetSelected"
              data-cy="continue"
            >
              Continue
            </v-btn>
            <v-btn text @click="previousStep"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <RowPicker v-if="worksheetSelected" />

            <v-btn
              color="accent"
              @click="checkRowSelectionAndContinue"
              :disabled="!rowsSelected"
              data-cy="continue"
            >
              Continue
            </v-btn>
            <v-btn text @click="previousStep"> Back </v-btn>

            <v-dialog v-model="confirmRowSelectionDialog" width="500">
              <v-card>
                <v-card-title>Confirm Row Selection</v-card-title>
                <v-card-text>
                  Your current row selection overlaps with the row selection from your
                  previous import. If this was intended you may click continue, otherwise
                  please click cancel and change the selected rows.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="confirmRowSelectionDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="confirmRowSelectionDialog = false; nextStep()"> Continue </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-stepper-content>

          <v-stepper-content step="4">
            <ColumnPicker v-if="worksheetSelected" />

            <v-btn
              color="accent"
              @click="nextStep"
              :disabled="!columnsSelected"
              data-cy="continue"
            >
              Continue
            </v-btn>
            <v-btn text @click="previousStep"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-content step="5">
            <GradeMapper
              v-if="worksheetSelected && columnsSelected"
            />

            <v-btn
              color="accent"
              @click="nextStep"
              :disabled="!gradesMapped"
              data-cy="continue"
            >
              Continue
            </v-btn>
            <v-btn text @click="previousStep"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-content step="6">
            <SpreadsheetViewer
              v-if="worksheetSelected"
              mode="verify"
            />

            <v-btn color="accent" @click="submitData"> Submit </v-btn>
            <v-btn text @click="previousStep"> Back </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-overlay :value="loadingOverlay">
        <v-alert v-if="submitSuccess" type="success" class="success-alert">
          Success!
        </v-alert>
        <v-progress-circular
          v-else
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import { mapStores } from 'pinia'

import MachineSelector from '@/components/DataImport/MachineSelector'
import SpreadsheetSelector from '@/components/DataImport/SpreadsheetSelector'
import RowPicker from '@/components/DataImport/RowPicker'
import ColumnPicker from '@/components/DataImport/ColumnPicker'
import GradeMapper from '@/components/DataImport/GradeMapper'
import SpreadsheetViewer from '@/components/DataImport/SpreadsheetViewer'

import { getInstance as getDataService } from '@/services/dataService'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

export default {
  name: 'DataImportScreen',
  components: {
    MachineSelector,
    SpreadsheetSelector,
    RowPicker,
    ColumnPicker,
    GradeMapper,
    SpreadsheetViewer
  },
  data() {
    return {
      dataService: null,
      currentStep: 1,
      loadingOverlay: false,
      submitSuccess: false,
      confirmRowSelectionDialog: false
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore),
    locationSelected() {
      return this.dataImportWorkflowStore.locationId
    },
    machineSelected() {
      return this.dataImportWorkflowStore.machineId
    },
    worksheetSelected() {
      return this.dataImportWorkflowStore.worksheet && this.dataImportWorkflowStore.filename
    },
    rowsSelected() {
      return this.dataImportWorkflowStore.startRow && this.dataImportWorkflowStore.endRow
    },
    columnsSelected() {
      let requiredColumnsSelected = 
        this.dataImportWorkflowStore.headboxConsistencyColumn.columnName && this.dataImportWorkflowStore.headboxConsistencyColumn.columnFormat
        && this.dataImportWorkflowStore.traySolidsColumn.columnName && this.dataImportWorkflowStore.traySolidsColumn.columnFormat
        && this.dataImportWorkflowStore.firstPassRetentionColumn.columnName && this.dataImportWorkflowStore.firstPassRetentionColumn.columnFormat
        && this.dataImportWorkflowStore.gradeColumn.columnName
        && this.dataImportWorkflowStore.machineSpeedColumn.columnName
        && this.dataImportWorkflowStore.conditionWeightColumn.columnName
        && this.dataImportWorkflowStore.dateColumn.columnName

      let lbsTonPolymerSelected = this.dataImportWorkflowStore.lbsTonPolymerConstant || this.dataImportWorkflowStore.lbsTonPolymerColumn.columnName

      return requiredColumnsSelected && lbsTonPolymerSelected
    },
    gradesMapped() {
      return this.dataImportWorkflowStore.gradeMap
    }
  },
  methods: {
    nextStep() {
      this.currentStep++
    },
    previousStep() {
      this.currentStep--
    },
    checkRowSelectionAndContinue() {
      if ((this.dataImportWorkflowStore.startRow < this.dataImportWorkflowStore.previousStartRow && this.dataImportWorkflowStore.endRow < this.dataImportWorkflowStore.previousStartRow)
        || (this.dataImportWorkflowStore.startRow > this.dataImportWorkflowStore.previousEndRow && this.dataImportWorkflowStore.endRow > this.dataImportWorkflowStore.previousEndRow)) {
          this.nextStep()
      } else {
        this.confirmRowSelectionDialog = true
      }
    },
    async submitData() {
      this.loadingOverlay = true

      const columnMap = {
        headboxConsistency: {
          name: this.dataImportWorkflowStore.headboxConsistencyColumn.columnName,
          format: this.dataImportWorkflowStore.headboxConsistencyColumn.columnFormat
        },
        traySolids: {
          name: this.dataImportWorkflowStore.traySolidsColumn.columnName,
          format: this.dataImportWorkflowStore.traySolidsColumn.columnFormat
        },
        firstPassRetention: {
          name: this.dataImportWorkflowStore.firstPassRetentionColumn.columnName,
          format: this.dataImportWorkflowStore.firstPassRetentionColumn.columnFormat
        },
        grade: {
          name: this.dataImportWorkflowStore.gradeColumn.columnName
        },
        machineSpeed: {
          name: this.dataImportWorkflowStore.machineSpeedColumn.columnName
        },
        conditionWeight: {
          name: this.dataImportWorkflowStore.conditionWeightColumn.columnName
        },
        lbsTonPolymer: {
          name: this.dataImportWorkflowStore.lbsTonPolymerColumn.columnName
        },
        date: {
          name: this.dataImportWorkflowStore.dateColumn.columnName
        }
      }
      
      await this.dataService.import(
        this.dataImportWorkflowStore.worksheet,
        this.dataImportWorkflowStore.locationId,
        this.dataImportWorkflowStore.machineId,
        this.dataImportWorkflowStore.ply,
        this.dataImportWorkflowStore.filename,
        this.dataImportWorkflowStore.worksheetName,
        this.dataImportWorkflowStore.startRow,
        this.dataImportWorkflowStore.endRow,
        this.dataImportWorkflowStore.headerRow,
        columnMap,
        this.dataImportWorkflowStore.gradeMap,
        this.dataImportWorkflowStore.lbsTonPolymerConstant
      )

      this.submitSuccess = true

      this.dataImportWorkflowStore.clearSpreadsheet()

      // Wait 2 seconds so that the user can see the success message
      await new Promise((res) => setTimeout(res, 2000))
      window.location.reload()
    }
  },
  async created() {
    if (!this.dataService) {
      this.dataService = getDataService()
    }
  }
}
</script>

<style scoped>
#spreadsheet-selector {
  margin-bottom: 20px;
}
</style>