import { defineStore } from 'pinia'
import { ref } from 'vue'

import { getInstance as getPaperGradeService } from '@/services/paperGradeService'

export const useSystemStore = defineStore('system', () => {
  let gradeService = null

  const grades = ref([])

  async function loadGrades() {
    if (gradeService == null) {
      gradeService = getPaperGradeService()
    }

    grades.value = await gradeService.getPaperGrades()

    return grades.value
  }

  return {
    grades,
    loadGrades
  }
})