<template>
  <FrequencyDistributionChart title="Lbs/Ton Polymer" :averageName="averageName" :maxValue="2" :minValue="0"
    :indexFactor="100" :frequencies="frequencies" :average="roundedAverage" :quartiles="formattedQuartiles"
    :stepSize="0.5" format="numeric" />
</template>

<script>
import FrequencyDistributionChart from '@/components/Reporting/FrequencyDistributionChart'

export default {
  components: {
    FrequencyDistributionChart
  },
  props: {
    average: {
      type: Number,
      required: false
    },
    averageName: {
      type: String,
      required: true
    },
    quartiles: {
      tye: Object,
      required: false
    },
    frequencies: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    roundedAverage() {
      if (!this.average) {
        return this.average
      }

      const roundedAverage = this.average.toFixed(2)

      return Number(roundedAverage)
    },
    formattedQuartiles() {
      if (!this.quartiles) {
        return this.quartiles
      }

      const roundedQuartiles = this.quartiles.map(q => q.toFixed(2))

      return roundedQuartiles
    }
  }
}

</script>