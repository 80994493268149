<template>
  <div class="center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.center {
  text-align: center;
}
</style>>
