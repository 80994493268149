module.exports = {
  auth0: {
    domain: 'nalco-water.us.auth0.com',
    audience: 'https://paper-tracking-api.nalcowater.com/',
    clientId: 'd3nzsU2JDgBWofvXln1fwj5W6RkNFNuX',
    customClaimNamespace: 'https://nalcowater.com'
  },
  contactEmail: 'jmorgan@ecolab.com',
  api: {
    baseUrl: process.env.VUE_APP_API_BASE_URL || `${window.location.origin}/api`
  },
  devtool: process.env.NODE_ENV == 'development' ? 'source-map' : undefined,
  standardErrorResponse: 'Something went wrong with the request. Please try again later.'
}
