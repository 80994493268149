import config from '../config'
import ApiService from './apiService';

let instance

export function getInstance() {
  if (!instance) {
    instance = new LocationService()
  }

  return instance
}

export default class LocationService extends ApiService {
  constructor() {
    super()
  }

  async getLocations() {
    const response = await this.axios.get(`${this.baseUrl}/locations`, await this.getStandardRequestConfig())

    return response.data.locations
  }

  async getUserLocation() {
    const locationId = this.auth.user[`${config.auth0.customClaimNamespace}/location`]

    return await this.getLocation(locationId)
  }

  async getLocation(id) {
    const response = await this.axios.get(`${this.baseUrl}/locations/${id}`, await this.getStandardRequestConfig())

    return response.data.location
  }

  async addLocation(name) {
    const location = {
      name: name
    }

    await this.axios.post(`${this.baseUrl}/locations`, location, await this.getStandardRequestConfig())
  }

  async deleteLocation(id) {
    try {
      await this.axios.delete(`${this.baseUrl}/locations/${id}`, await this.getStandardRequestConfig())
    } catch (e) {
      if (e.response.status === 400) {
        return {
          error: e.response.data
        }
      } else {
        return {
          error: config.standardErrorResponse
        }
      }
    }

    return {}
  }

  async deactivateLocation(id) {
    await this.axios.post(`${this.baseUrl}/locations/${id}/deactivate`, {}, await this.getStandardRequestConfig())
  }

  async updateLocation(id, name) {
    const location = {
      name: name
    }

    await this.axios.patch(`${this.baseUrl}/locations/${id}`, location, await this.getStandardRequestConfig())
  }

  async activateLocation(id) {
    await this.axios.post(`${this.baseUrl}/locations/${id}/activate`, {}, await this.getStandardRequestConfig())
  }

  async addMachine(locationId, name) {
    const machine = {
      name: name
    }

    await this.axios.post(`${this.baseUrl}/locations/${locationId}/machines`, machine, await this.getStandardRequestConfig())
  }

  async updateMachine(locationId, machineId, name) {
    const machine = {
      name: name
    }

    await this.axios.patch(`${this.baseUrl}/locations/${locationId}/machines/${machineId}`, machine, await this.getStandardRequestConfig())
  }

  async deleteMachine(locationId, machineId) {
    await this.axios.delete(`${this.baseUrl}/locations/${locationId}/machines/${machineId}`, await this.getStandardRequestConfig())
  }

  async deactivateMachine(locationId, machineId) {
    await this.axios.post(`${this.baseUrl}/locations/${locationId}/machines/${machineId}/deactivate`, {}, await this.getStandardRequestConfig())
  }

  async activateMachine(locationId, machineId) {
    await this.axios.post(`${this.baseUrl}/locations/${locationId}/machines/${machineId}/activate`, {}, await this.getStandardRequestConfig())
  }
}