<template>
  <div id="nav">
    <v-tabs v-model="currentTab">
      <v-tab @click="navigate('/')">Home</v-tab>
      <v-tab @click="navigate('/DataImport')" :disabled="!isActivated">Data Import</v-tab>
      <v-tab @click="navigate('/Reporting')" :disabled="!isActivated">Reporting</v-tab>
      <v-tab v-if="isAdmin" @click="navigate('/UserManagement')">User Management</v-tab>
      <v-tab v-if="isAdmin" @click="navigate('/ImportManagement')">Import Management</v-tab>
      <v-tab v-if="isAdmin" @click="navigate('/SystemManagement')">System Management</v-tab>
    </v-tabs>
  </div>
</template>

<script>
import config from '@/config'

export default {
  data() {
    return {
      currentTab: null,
      tabMap: {
        Home: 0,
        DataImport: 1,
        Reporting: 2,
        UserManagement: 3,
        ImportManagement: 4,
        SystemManagement: 5
      }
    }
  },
  computed: {
    roles() {
      const roles = this.$auth.user[`${config.auth0.customClaimNamespace}/roles`]

      if (!roles) {
        return []
      }

      return roles
    },
    isActivated() {
      return this.roles.includes('Worker') || this.roles.includes('Admin')
    },
    isAdmin() {
      return this.roles.includes('Admin')
    }
  },
  methods: {
    navigate(location) {
      if (location != this.$route.path) {
        this.$router.push(location)
      }
    }
  },
  async created() {
    this.currentTab = this.tabMap[this.$route.name]
  }
}
</script>
