<template>
  <v-card id="grade-mapper" class="grade-mapper-container" outlined>
    <div v-if="!loading">
      <v-alert v-if="states.includes(allowedStates.noAction)" border="top" type="success" text color="accent">
        All grades for the current import were able to be inferred. No action
        needed.
      </v-alert>
      <v-alert v-if="states.includes(allowedStates.missingGrades)" border="top" type="warning" text color="warning">
        Some rows were found with no grade. These rows will be excluded from the
        import. If this was a mistake please double check the selected grade
        column or re-upload the data with the required grade.
      </v-alert>
      <v-alert v-if="states.includes(allowedStates.mapNeeded)" border="top" type="info" text color="primary">
        Some of the grades in the current import could not be mapped to their
        corrisponding type. Using the table below please select the appropriate
        grade for each row. (If the corrisponding grade cannot be found please
        contact the system administrator)
      </v-alert>
      <v-alert v-if="states.includes(allowedStates.noGrades)" border="top" type="error" text color="error">
        Could not find any grades for the current import. Please make sure that
        the correct column was selected for grade.
      </v-alert>

      <v-data-table v-if="states.includes(allowedStates.mapNeeded)" :headers="mapHeaders" :items="gradesToMap">
        <template v-slot:item.actual="{ item }">
          <v-select 
            data-cy="grade-selector" 
            v-model="gradeMap[item.grade].name" 
            :items="systemStore.grades" 
            item-text="name"
            item-value="name" 
            v-on:change="(selectedGradeId) => mapGradeAlias(selectedGradeId, item.grade)" 
          />
        </template>
        <template v-slot:item.skip="{ item }">
          <v-checkbox 
            data-cy="grade-skip-checbox" 
            v-model="gradeMap[item.grade].skip"
            v-on:change="(skip) => mapGradeAlias(null, item.grade, skip)" 
          />
        </template>
      </v-data-table>
    </div>
    <Loader v-if="loading" />
  </v-card>
</template>

<script>
import { mapStores } from 'pinia'

import Loader from '@/components/Parts/Loader'

import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'
import { useSystemStore } from '@/stores/system'

export default {
  name: 'GradeMapper',
  components: {
    Loader
  },
  data() {
    return {
      loading: true,
      states: [],
      allowedStates: {
        noAction: 'no-action',
        mapNeeded: 'map-needed',
        missingGrades: 'missing-grades',
        noGrades: 'no-grades'
      },
      mapHeaders: [
        {
          text: 'UnknownGrade',
          align: 'start',
          sortable: true,
          value: 'grade'
        },
        {
          text: 'ActualGrade',
          sortable: false,
          value: 'actual'
        },
        {
          text: 'Skip',
          sortable: false,
          value: 'skip'
        }
      ],
      gradesToMap: [],
      gradeMap: {}
    }
  },
  watch: {
    'dataImportWorkflowStore.startRow'() {
      this.resetGradeMap()
    },
    'dataImportWorkflowStore.endRow'() {
      this.resetGradeMap()
    },
    'dataImportWorkflowStore.gradeColumn.columnName'() {
      this.resetGradeMap()
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore, useSystemStore),
    selectedRows() {
      return this.dataImportWorkflowStore.worksheet.slice(this.dataImportWorkflowStore.startRow - 1, this.dataImportWorkflowStore.endRow - 1)
    },
    spreadsheetGrades() {
      const allGrades = this.selectedRows.map((row) => row[this.dataImportWorkflowStore.gradeColumn.columnName])
      const uniqueGrades = [...new Set(allGrades)]

      const validGrades = uniqueGrades.filter((g) => {
        if (g) {
          return true
        }
      })

      return validGrades.map((g) => {
        return { grade: g }
      })
    },
    isMissingGrade() {
      const allGrades = this.selectedRows.map((row) => row[this.dataImportWorkflowStore.gradeColumn.columnName])
      return allGrades.some((g) => !g)
    }
  },
  methods: {
    setState() {
      this.states = []

      if (this.spreadsheetGrades.length === 0) {
        this.states.push(this.allowedStates.noGrades)
        return
      }

      if (this.isMissingGrade) {
        this.states.push(this.allowedStates.missingGrades)
      }

      if (!this.areGradesMapped()) {
        this.states.push(this.allowedStates.mapNeeded)
      } else {
        this.states.push(this.allowedStates.noAction)
      }
    },
    mapGradeAlias(gradeName, gradeAlias, skip = false) {
      this.$set(this.gradeMap, gradeAlias, {
        name: gradeName,
        skip
      })

      if (this.areGradesMapped()) {
        this.dataImportWorkflowStore.setGradeMap(this.gradeMap)
      } else {
        this.dataImportWorkflowStore.setGradeMap(null)
      }
    },
    autoMapGrades() {
      let remainingGradesToMap = [...this.gradesToMap]
      
      for (const gradeToMap of this.gradesToMap) {
        const matchedGrade = this.systemStore.grades.find(g => g.name == gradeToMap.grade || g.aliases.includes(gradeToMap.grade))
        
        if (matchedGrade) {
          this.mapGradeAlias(matchedGrade.name, gradeToMap.grade, false)
          remainingGradesToMap = remainingGradesToMap.filter(g => g.grade !== gradeToMap.grade)
        }
      }

      this.gradesToMap = remainingGradesToMap
    },
    areGradesMapped() {
      const mappedGrades = Object.entries(this.gradeMap).map(g => g[0])

      // Ensure that all grades that need to be mapped have been added to the grade map
      for (const grade of this.gradesToMap) {
        if (!mappedGrades.includes(grade.grade)) {
          return false
        }
      }

      // Ensure that each grade in the grade map either has a value or was skipped
      for (const mappedGrade of Object.entries(this.gradeMap)) {
        const mappedGradeData = mappedGrade[1]

        if (!mappedGradeData.name && mappedGradeData.skip == false) {
          return false
        }
      }

      return true
    },
    resetGradeMap() {
      this.gradeMap = {}
      this.gradesToMap = this.spreadsheetGrades.filter(
        (g) => !Object.keys(this.gradeMap).includes(g.grade)
      )

      for (const gradeToMap of this.gradesToMap) {
        this.$set(this.gradeMap, gradeToMap.grade, {
          name: null,
          skip: false
        })
      }

      this.autoMapGrades()
      this.setState()
    }
  },
  async created() {
    this.loading = true
    await this.systemStore.loadGrades()
    
    this.resetGradeMap()
    this.loading = false
  }
}
</script>

<style scoped>
.grade-mapper-container {
  margin-bottom: 10px;
}

.progress {
  padding: 50px;
}
</style>