import config from '../config'
import { getInstance as getAuthInstance } from '@/plugins/authentication'

const axios = require('axios');

export default class ApiService {
  constructor() {
    this.auth = getAuthInstance()
    this.baseUrl = config.api.baseUrl
    this.axios = axios
  }

  async getStandardRequestConfig() {
    const requestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.getAccessToken()}`
      }
    }

    return requestConfig
  }

  async getAccessToken() {
    return await this.auth.getAccessToken()
  }
}
