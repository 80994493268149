import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import DataImport from '@/views/DataImport.vue'
import Reporting from '@/views/Reporting.vue'
import UserManagement from '@/views/UserManagement.vue'
import ImportManagement from '@/views/ImportManagement.vue'
import SystemManagement from '@/views/SystemManagement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/DataImport',
    name: 'DataImport',
    component: DataImport
  },
  {
    path: '/Reporting',
    name: 'Reporting',
    component: Reporting
  },
  {
    path: '/UserManagement',
    name: 'UserManagement',
    component: UserManagement
  },
  {
    path: '/ImportManagement',
    name: 'ImportManagement',
    component: ImportManagement
  },
  {
    path: '/SystemManagement',
    name: 'SystemManagement',
    component: SystemManagement
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Nalco Analytics'
  })
})

export default router
