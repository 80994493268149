<template>
  <v-container fluid id="import-management">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Manage Data Imports</v-card-title>
          <v-container fluid>
            <v-row>
              <v-col>
                <h4>Filter:</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Location"
                  v-model="selectedLocation"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="clearSelectedLocation"></v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Machine"
                  v-model="selectedMachine"
                  :items="machines"
                  item-text="name"
                  item-value="id"
                  :disabled="!selectedLocation"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="clearSelectedMachine"></v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>Imports:</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="imports.imports"
                  :server-items-length="imports.count"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [10, 25, 50, 100]
                  }"
                >
                  <template v-slot:item.importDate="{ item }">
                    {{ formatDate(item.importDate) }}
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="text-decoration-underline error--text"
                      plain
                      x-small
                      @click="openDeleteImportConfirm(item.id)"
                    >
                      Delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="deleteImportConfirm" width="500">
      <v-card>
        <v-card-title> Confirm Deletion </v-card-title>
        <v-card-text>
          <p>
            Are you sure you want to delete this import? This will delete data
            associated with this import. This action cannot be undone.
          </p>
          <strong>Note: </strong>It can take up to 2 hours for the system to
          fully reflect this deletion.</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteImportConfirm = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteImport"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LocationService from '@/services/locationService'
import ImportInfoService from '@/services/importInfoService'
import moment from 'moment'

let locationService = null

export default {
  data() {
    return {
      test: [{ text: 'someText', value: 'somevalue' }],
      loading: false,
      options: {},
      headers: [
        {
          text: 'Filename',
          align: 'start',
          sortable: false,
          value: 'fileName'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location.name'
        },
        {
          text: 'Machine',
          sortable: false,
          value: 'machine.name'
        },
        {
          text: 'Ply',
          sortable: false,
          value: 'ply'
        },
        {
          text: 'Date Imported',
          sortable: false,
          value: 'importDate'
        },
        {
          text: 'Imported By',
          sortable: true,
          value: 'user'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          align: 'end'
        }
      ],
      imports: [],
      locations: [],
      machines: [],
      selectedLocation: null,
      selectedMachine: null,
      deleteImportConfirm: false,
      importToDelete: null,
      importInfoService: null
    }
  },
  watch: {
    options: {
      handler() {
        this.getImports()
      },
      deep: true
    },
    selectedLocation(val) {
      this.selectedMachine = null

      if (val != null) {
        this.getMachines(val)
      }

      this.getImports()
    },
    selectedMachine() {
      this.getImports()
    }
  },
  methods: {
    async getImports() {
      this.loading = true

      let top = 10
      let skip = 0

      if (this.options.itemsPerPage && this.options.page) {
        top = this.options.itemsPerPage
        skip = (this.options.page - 1) * top
      }

      this.imports = await this.importInfoService.getImports(
        this.selectedLocation,
        this.selectedMachine,
        top,
        skip
      )

      this.loading = false
    },
    async getLocations() {
      this.locations = await locationService.getLocations()
    },
    getMachines(locationId) {
      this.machines = this.locations.filter(
        (l) => l.id == locationId
      )[0].machines
    },
    async clearSelectedMachine() {
      this.selectedMachine = null
    },
    async clearSelectedLocation() {
      this.selectedLocation = null
      this.selectedMachine = null
    },
    formatDate(date) {
      return moment.utc(date).local().format('MMMM Do YYYY, h:mm a')
    },
    openDeleteImportConfirm(importId) {
      this.deleteImportConfirm = true
      this.importToDelete = this.imports.imports.filter(
        (i) => i.id == importId
      )[0]
    },
    async deleteImport() {
      this.loading = true
      this.deleteImportConfirm = false

      await this.importInfoService.deleteImport(this.importToDelete.id)
      this.importToDelete = null

      await this.getImports()
    }
  },
  async created() {
    locationService = new LocationService(this.$auth)
    this.importInfoService = new ImportInfoService()

    await Promise.all([this.getImports(), this.getLocations()])
  }
}
</script>
