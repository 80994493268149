<template>
  <v-card class="column-picker-container" outlined>
    <v-stepper v-model="currentStep" vertical>
      <v-stepper-step :complete="currentStep > 1" step="1">
        Headbox Consistency
        <small v-if="currentStep == 1">
          Select the column that corrisponds to headbox consistency.
        </small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <div class="column-selector">
          <v-select
            @change="onHeadboxConsistencyColumnNameChange"
            :value="dataImportWorkflowStore.headboxConsistencyColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-headbox-consistency"
          />
        </div>

        <SpreadsheetViewer :mode="'columnPicker'" />

        <div>Is the selected column in numeric or percent format?</div>
        <div class="column-selector">
          <v-select 
            @change="onHeadboxConsistencyColumnFormatChange"
            :value="dataImportWorkflowStore.headboxConsistencyColumn.columnFormat"
            :items="columnFormats"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-headbox-consistency-format"
          />
        </div>

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.headboxConsistencyColumn.columnName || !dataImportWorkflowStore.headboxConsistencyColumn.columnFormat"
          @click="currentStep = 2"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">
        Tray Solids
        <small v-if="currentStep == 2">
          Select the column that corrisponds to tray solids.
        </small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <div class="column-selector">
          <v-select
            @change="onTraySolidsColumnNameChange"
            :value="dataImportWorkflowStore.traySolidsColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-tray-solids"
          ></v-select>
        </div>
        <SpreadsheetViewer :mode="'columnPicker'" />
        <div>Is the selected column in numeric or percent format?</div>
        <div class="column-selector">
          <v-select 
            @change="onTraySolidsColumnFormatChange"
            :value="dataImportWorkflowStore.traySolidsColumn.columnFormat"
            :items="columnFormats"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-tray-solids-format"
          />
        </div>

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.traySolidsColumn.columnName || !dataImportWorkflowStore.traySolidsColumn.columnFormat"
          @click="currentStep = 3"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 1"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 3" step="3">
        First Pass Retention
        <small v-if="currentStep == 3">
          Select the column that corrisponds to first pass retention.
        </small>
      </v-stepper-step>
      <v-stepper-content step="3">
        <div class="column-selector">
          <v-select
            @change="onFirstPassRetentionColumnNameChange"
            :value="dataImportWorkflowStore.firstPassRetentionColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-first-pass-retension"
          ></v-select>
        </div>
        <SpreadsheetViewer :mode="'columnPicker'" />
        <div>Is the selected column in numeric or percent format?</div>
        <div class="column-selector">
          <v-select 
            @change="onFirstPassRetentionColumnFormatChange"
            :value="dataImportWorkflowStore.firstPassRetentionColumn.columnFormat"
            :items="columnFormats"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-first-pass-retension-format"
          />
        </div>

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.firstPassRetentionColumn.columnName || !dataImportWorkflowStore.firstPassRetentionColumn.columnFormat"
          @click="currentStep = 4"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 2"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 4" step="4">
        Grade
        <small v-if="currentStep == 4">
          Select the column that corrisponds to grade.
        </small>
      </v-stepper-step>
      <v-stepper-content step="4">
        <div class="column-selector">
          <v-select
            @change="onGradeColumnNameChange"
            :value="dataImportWorkflowStore.gradeColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-grade"
          ></v-select>
        </div>
        <SpreadsheetViewer :mode="'columnPicker'" />

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.gradeColumn.columnName"
          @click="currentStep = 5"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 3"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 5" step="5">
        Machine Speed
        <small v-if="currentStep == 5">
          Select the column that corrisponds to speed.
        </small>
      </v-stepper-step>
      <v-stepper-content step="5">
        <div class="column-selector">
          <v-select
            @change="onMachineSpeedColumnNameChange"
            :value="dataImportWorkflowStore.machineSpeedColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-machine-speed"
          ></v-select>
        </div>
        <SpreadsheetViewer :mode="'columnPicker'" />

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.machineSpeedColumn.columnName"
          @click="currentStep = 6"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 4"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 6" step="6">
        Condition Weight
        <small v-if="currentStep == 6">
          Select the column that corrisponds to condition weight.
        </small>
      </v-stepper-step>
      <v-stepper-content step="6">
        <div class="column-selector">
          <v-select
            @change="onConditionWeightColumnNameChange"
            :value="dataImportWorkflowStore.conditionWeightColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-condition-weight"
          ></v-select>
        </div>
        <SpreadsheetViewer :mode="'columnPicker'" />

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.conditionWeightColumn.columnName"
          @click="currentStep = 7"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 5"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 7" step="7">
        Lbs/Ton polymer
        <small v-if="currentStep == 7">
          Select the column that corrisponds to lbs/ton polymer.
        </small>
      </v-stepper-step>
      <v-stepper-content step="7">
        <v-container fluid class="lbs-ton-polymer-selector-container">
          <v-row>
            <v-col>
              <div class="column-selector">
                <v-select
                  @change="onLbsTonPolymerColumnNameChange"
                  :value="dataImportWorkflowStore.lbsTonPolymerColumn.columnName"
                  :disabled="isLbsTonPolymerConstant"
                  :items="columns"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  data-cy="select-input-lbs-ton-polymer"
                ></v-select>
              </div>
            </v-col>
            <v-col>
              <div>
                <v-checkbox
                  v-model="isLbsTonPolymerConstant"
                  label="Lbs/Ton Polymer is missing/constant."
                />
              </div>
            </v-col>
            <v-col>
              <div class="lbs-ton-polymer-input">
                <v-text-field
                  @change="onLbsTonPolymerChange"
                  :value="dataImportWorkflowStore.lbsTonPolymerConstant"
                  type="number"
                  :disabled="!isLbsTonPolymerConstant"
                  outlined
                  dense
                  max="1"
                  min="0"
                  step="0.01"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SpreadsheetViewer :mode="'columnPicker'" />
            </v-col>
          </v-row>
        </v-container>

        <v-btn
          color="primary"
          :disabled="!dataImportWorkflowStore.lbsTonPolymerColumn.columnName && !dataImportWorkflowStore.lbsTonPolymerConstant"
          @click="currentStep = 8"
          data-cy="sub-continue"
        >
          Continue
        </v-btn>
        <v-btn text @click="currentStep = 6"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 8" step="8">
        Date
        <small v-if="currentStep == 8">
          Select the column that corrisponds to the date.
        </small>
      </v-stepper-step>
      <v-stepper-content step="8">
        <div class="column-selector">
          <v-select
            @change="onDateColumnNameChange"
            :value="dataImportWorkflowStore.dateColumn.columnName"
            :items="columns"
            item-text="text"
            item-value="value"
            outlined
            dense
            data-cy="select-input-date"
          ></v-select>
        </div>
        <SpreadsheetViewer mode="columnPicker" />

        <v-btn text @click="currentStep = 7"> Back </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapStores } from 'pinia'

import SpreadsheetViewer from './SpreadsheetViewer'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

export default {
  name: 'ColumnPicker',
  components: {
    SpreadsheetViewer
  },
  data() {
    return {
      currentStep: 1,
      isLbsTonPolymerConstant: false,
      columnFormats: [
        {
          text: 'Numeric',
          value: 'numeric'
        },
        {
          text: 'Percent',
          value: 'percent'
        }
      ]
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore),
    columns() {
      if (this.dataImportWorkflowStore.worksheet.length === 0) {
        return []
      }

      let columns = []

      for (const header of Object.keys(this.dataImportWorkflowStore.worksheet[0])) {
        let columnValue = header
        let columnText = header

        if (this.mode !== 'rowPicker' && this.dataImportWorkflowStore.headerRow) {
          columnText = this.dataImportWorkflowStore.worksheet[this.dataImportWorkflowStore.headerRow - 1][columnValue]
        }

        columns.push({
          text: columnText,
          value: columnValue
        })
      }

      return columns
    },
    areAllColumnsSelected() {
      return this.selectedColumns.headboxConsistency &&
          this.selectedColumns.traySolids &&
          this.selectedColumns.firstPassRetention &&
          this.selectedColumns.grade &&
          this.selectedColumns.machineSpeed &&
          this.selectedColumns.conditionWeight &&
          (this.selectedColumns.lbsTonPolymer || this.dataImportWorkflowStore.lbsTonPolymer) &&
          this.selectedColumns.date
    }
  },
  watch: {
    isLbsTonPolymerConstant(value) {
      if (value) {
        this.dataImportWorkflowStore.setLbsTonPolymerColumnName(null)
      } else {
        this.dataImportWorkflowStore.setLbsTonPolymer(null)
      }
    }
  },
  methods: {
    incrementLbsTonPolymer() {
      this.dataImportWorkflowStore.setLbsTonPolymer(parseFloat(this.dataImportWorkflowStore.lbsTonPolymer) + 0.1)
    },
    onHeadboxConsistencyColumnNameChange(value) {
      this.dataImportWorkflowStore.setHeadboxConsistencyColumnName(value)
    },
    onHeadboxConsistencyColumnFormatChange(value) {
      this.dataImportWorkflowStore.setHeadboxConsistencyColumnFormat(value)
    },
    onTraySolidsColumnNameChange(value) {
      this.dataImportWorkflowStore.setTraySolidsColumnName(value)
    },
    onTraySolidsColumnFormatChange(value) {
      this.dataImportWorkflowStore.setTraySolidsColumnFormat(value)
    },
    onFirstPassRetentionColumnNameChange(value) {
      this.dataImportWorkflowStore.setFirstPassRetentionColumnName(value)
    },
    onFirstPassRetentionColumnFormatChange(value) {
      this.dataImportWorkflowStore.setFirstPassRetentionColumnFormat(value)
    },
    onGradeColumnNameChange(value) {
      this.dataImportWorkflowStore.setGradeColumnName(value)
    },
    onMachineSpeedColumnNameChange(value) {
      this.dataImportWorkflowStore.setMachineSpeedColumnName(value)
    },
    onConditionWeightColumnNameChange(value) {
      this.dataImportWorkflowStore.setConditionWeightColumnName(value)
    },
    onLbsTonPolymerColumnNameChange(value) {
      this.dataImportWorkflowStore.setLbsTonPolymerColumnName(value)
    },
    onLbsTonPolymerChange(value) {
      this.dataImportWorkflowStore.setLbsTonPolymer(value)
    },
    onDateColumnNameChange(value) {
      this.dataImportWorkflowStore.setDateColumnName(value)
    }
  },
  async created() {
    if (this.dataImportWorkflowStore.lbsTonPolymerConstant) {
      this.isLbsTonPolymerConstant = true
    }
  }
}
</script>

<style scoped>
.column-picker-container {
  margin-bottom: 10px;
}

.column-selector {
  width: 250px;
}

.lbs-ton-polymer-input {
  width: 75px;
}

.lbs-ton-polymer-selector-container {
  padding: 0;
}

.outline {
  border: dotted red 3px;
}
</style>