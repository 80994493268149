<template>
  <div id="spreadsheet-selector">
    <h2>Select Spreadsheet</h2>
    <v-file-input
      class="mt-2"
      truncate-length="50"
      chips
      outlined
      counter
      show-size
      label="Machine Data"
      data-cy="file-input"
      @change="onFileSelected"
    />
    <div v-if="loading" class="floating-loader">
      Loading...
      <Loader />
    </div>

    <h2>Select Worksheet</h2>
    <v-radio-group v-if="dataImportWorkflowStore.spreadsheet && !loading" :value="dataImportWorkflowStore.worksheetName" @change="onWorksheetSelected">
      <v-radio
        v-for="worksheetName in dataImportWorkflowStore.spreadsheet.SheetNames"
        :key="worksheetName"
        :label="worksheetName"
        :value="worksheetName"
      />
    </v-radio-group>
    <div v-else>No spreadsheet selected.</div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'

import Loader from '@/components/Parts/Loader'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

export default {
  name: 'SpreadsheetSelector',
  components: {
    Loader
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore)
  },
  methods: {
    async onFileSelected(file) {
      if (!file) {
        this.spreadsheet = this.dataImportWorkflowStore.clearSpreadsheet()

        return
      }

      this.loading = true
      await this.dataImportWorkflowStore.loadNewSpreadsheet(file)
      this.loading = false
    },
    onWorksheetSelected(name) {
      this.dataImportWorkflowStore.selectWorksheet(name)
    }
  },
}
</script>

<style scoped>
.floating-loader {
  position: absolute;
  left: 50%;
}
</style>