<template>
  <FrequencyDistributionChart title="First Pass Retention" :averageName="averageName" :maxValue="100" :minValue="50"
    :indexFactor="1" :frequencies="formattedFrequencies" :average="roundedAverage" :quartiles="formattedQuartiles"
    :stepSize="10" format="percent" />
</template>

<script>
import FrequencyDistributionChart from '@/components/Reporting/FrequencyDistributionChart'

export default {
  components: {
    FrequencyDistributionChart
  },
  props: {
    average: {
      type: Number,
      required: false
    },
    averageName: {
      type: String,
      required: true
    },
    quartiles: {
      tye: Object,
      required: false
    },
    frequencies: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    roundedAverage() {
      if (!this.average) {
        return this.average
      }

      const roundedAverage = (this.average * 100).toFixed(0)

      return Number(roundedAverage)
    },
    formattedQuartiles() {
      if (!this.quartiles) {
        return this.quartiles
      }

      const roundedQuartiles = this.quartiles.map(q => (q * 100).toFixed(0))
      const formattedQuartiles = roundedQuartiles.map(q => `${q}%`)

      return formattedQuartiles
    },
    formattedFrequencies() {
      if (!this.frequencies) {
        return this.frequencies
      }

      const newFreq = this.frequencies.map(f => ({
        count: f.count,
        value: f.value * 100
      }))

      return newFreq
    }
  }
}

</script>