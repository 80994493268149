<template>
  <v-card>
    <v-card-title>Manage Paper Grades</v-card-title>
    <v-data-table
      :headers="headers"
      :items="paperGrades"
      :items-per-page="10"
      :loading="loading"
    >
      <template v-slot:top>
        <v-btn
          small
          plain
          color="primary"
          class="ma-1"
          @click="openDialog('new')"
        >
          Add Paper Grade
        </v-btn>
        <v-dialog v-model="dialogOpen" max-width="500px">
          <v-card>
            <v-card-title>
              <div class="text-h5">{{ dialogTitle }}</div>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Grade Code"
                v-model="editedPaperGrade.name"
                :rules="newGradeRules"
                :error-messages="nameValidationMessage"
              />
              <v-text-field
                label="Description"
                v-model="editedPaperGrade.description"
              />
              <div>
                <h1>Aliases</h1>
                <v-chip-group column>
                  <v-chip 
                    v-for="alias in editedPaperGrade.aliases" 
                    :key="alias"
                    close
                    @click:close="removeAlias(alias)"
                  >
                    {{ alias }}
                  </v-chip>
                  <v-btn icon color="primary" @click="newAliasDialogOpen = true">
                    <v-icon>mdi-plus</v-icon>
                    <v-dialog v-model="newAliasDialogOpen" max-width="500px">
                      <v-card>
                        <v-card-title>Add Alias</v-card-title>
                        <v-card-text>
                          <v-text-field
                            label="Alias"
                            v-model="editedPaperGrade.newAlias"
                          />
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="closeNewAliasDialog">
                            Cancel
                          </v-btn>
                          <v-btn color="primary" text @click="addNewAlias" :disabled="!editedPaperGrade.newAlias">
                            Save
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-btn>
                </v-chip-group>
                <div v-if="aliasValidationMessage" class="error--text">{{ aliasValidationMessage }}</div>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">
                  Cancel
                </v-btn>
                <v-btn color="primary" text @click="savePaperGrade" :disabled="!editedPaperGrade.name">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          class="text-decoration-underline primary--text"
          plain
          x-small
          @click="openDialog('edit', item.id)"
        >
          Edit
        </v-btn>
        <span>|</span>
        <v-btn
          class="text-decoration-underline error--text"
          plain
          x-small
          @click="openDeleteConfirmationDialog(item.id)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="confirmDeleteDialog" width="500">
      <v-card>
        <v-card-title> Confirm Deletion </v-card-title>
        <v-card-text>Are you sure you want to delete this grade? This action cannot be undone.</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDeleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteGrade"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import PaperGradeService from '@/services/paperGradeService'

let paperGradeService = null

export default {
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description'
        },
        {
          text: 'Aliases',
          sortable: false,
          value: 'aliases'
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'end',
          value: 'actions'
        }
      ],
      paperGrades: [],
      dialogOpen: false,
      dialogMode: 'new',
      newAliasDialogOpen: false,
      editedPaperGrade: {
        id: null,
        name: null,
        description: null,
        aliases: null
      },
      nameValidationMessage: null,
      aliasValidationMessage: null,
      newGradeRules: [
        value => !!value || 'Required.'
      ],
      confirmDeleteDialog: false
    }
  },
  computed: {
    dialogTitle() {
      return this.dialogMode === 'new' ? 'New Paper Grade' : 'Edit Paper Grade'
    }
  },
  watch: {
    dialogOpen(val) {
      if (!val) {
        this.clearEditedPaperGrade()
      }
    }
  },
  methods: {
    openDialog(mode, id) {
      this.dialogMode = mode

      this.nameValidationMessage = null
      this.aliasValidationMessage = null

      if (mode === 'edit') {
        this.editedPaperGrade.id = id

        this.editedPaperGrade.name = this.paperGrades.find(
          (l) => l.id == id
        ).name

        this.editedPaperGrade.description = this.paperGrades.find(
          (l) => l.id == id
        ).description

        this.editedPaperGrade.aliases = [...this.paperGrades.find(
          (l) => l.id == id
        ).aliases]
      }

      this.dialogOpen = true
    },
    closeDialog() {
      this.dialogOpen = false
      this.clearEditedPaperGrade()
    },
    async savePaperGrade() {
      this.loading = true
      this.nameValidationMessage = null
      this.aliasValidationMessage = null

      try {
        if (this.dialogMode === 'new') {
          await paperGradeService.addPaperGrade(this.editedPaperGrade.name, this.editedPaperGrade.description)
        } else if (this.dialogMode === 'edit' && this.editedPaperGrade.id) {
          await paperGradeService.updatePaperGrade(
            this.editedPaperGrade.id,
            this.editedPaperGrade.name,
            this.editedPaperGrade.description,
            this.editedPaperGrade.aliases
          )
        }
      } catch (e) {

        if (e.response?.data?.errorCode == 'grade-exists') {
          this.nameValidationMessage = e.response?.data?.message
        } else if (e.response?.data?.errorCode == 'duplicate-alias' || e.response?.data?.errorCode == 'alias-exists') {
          this.aliasValidationMessage = e.response?.data?.message
        }

        this.loading = false
        return
      }

      this.paperGrades = await paperGradeService.getPaperGrades()

      this.loading = false
      this.closeDialog()
    },
    async deletePaperGrade(id) {
      this.loading = true

      await paperGradeService.deletePaperGrade(id)
      this.paperGrades = await paperGradeService.getPaperGrades()

      this.loading = false
    },
    clearEditedPaperGrade() {
      this.editedPaperGrade = {
        id: null,
        name: null,
        description: null,
        aliases: null
      }
    },
    async onMachineUpdateComplete() {
      this.paperGrades = await paperGradeService.getPaperGrades()
      this.loading = false
    },
    openDeleteConfirmationDialog(id) {
      this.confirmDeleteDialog = true;
      this.editedPaperGrade.id = id;
    },
    async deleteGrade() {
      this.loading = true
      this.confirmDeleteDialog = false

      await paperGradeService.deletePaperGrade(this.editedPaperGrade.id)
      this.paperGrades = await paperGradeService.getPaperGrades()

      this.loading = false
    },
    removeAlias(alias) {
      let aliasIndex = this.editedPaperGrade.aliases.indexOf(alias)
      if (aliasIndex > -1) {
        this.editedPaperGrade.aliases.splice(aliasIndex, 1)
      }
    },
    addNewAlias() {
      this.editedPaperGrade.aliases.push(this.editedPaperGrade.newAlias)
      this.closeNewAliasDialog()
    },
    closeNewAliasDialog() {
      this.newAliasDialogOpen = false
      this.editedPaperGrade.newAlias = null
    }
  },
  async created() {
    paperGradeService = new PaperGradeService(this.$auth)

    this.loading = true
    this.paperGrades = await paperGradeService.getPaperGrades()
    this.loading = false
  }
}
</script>
