import createAuth0Client from '@auth0/auth0-spa-js';
import Vue from 'vue';
import config from '../config'

let instance

export const getInstance = () => instance;

export const useAuth0 = () => {
  if (!instance) {
    instance = new Vue({
      data() {
        return {
          loading: true,
          auth0Client: null,
          user: {},
          isAuthenticated: false
        }
      },
      methods: {
        async login() {
          await this.auth0Client.loginWithRedirect()

          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
        },
        async logout() {
          await this.auth0Client.logout({
            returnTo: window.location.origin
          })
        },
        async getAccessToken() {
          return await this.auth0Client.getTokenSilently()
        }
      },
      async created() {
        const debug = window.sessionStorage.getItem('paper-analytics-debug')

        this.auth0Client = await createAuth0Client({
          domain: config.auth0.domain,
          audience: config.auth0.audience,
          client_id: config.auth0.clientId,
          redirect_uri: window.location.origin,
          scope: 'openid',
          cacheLocation: debug ? 'localstorage' : 'memory'
        })

        if (window.location.search.includes("code=") || window.location.search.includes("state=")) {
          try {
            await this.auth0Client.handleRedirectCallback()
          } catch (e) {
            // Mabye we should do something here? idk
          }
        }

        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.loading = false

        if (!this.isAuthenticated) {
          await this.login()
        }
      }
    })

    return instance
  }
}

export default {
  install(Vue) {
    Vue.prototype.$auth = useAuth0()
  }
}