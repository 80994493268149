<script>
// NOTE: We can't snapshot test this since it uses the canvas.
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    average: {
      type: Number,
      required: false
    },
    averageName: {
      type: String,
      required: true
    },
    quartiles: {
      tye: Object,
      required: false
    },
    frequencies: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    maxValue: {
      type: Number,
      required: true
    },
    minValue: {
      type: Number,
      required: true
    },
    indexFactor: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    stepSize: {
      type: Number,
      required: true
    },
    format: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emptyChart: {
        labels: [0, 0.125, 0.25, 0.5, 0.75, 0.875, 1],
        datasets: [
          {
            label: 'Global',
            data: [0, 5, 25, 100, 25, 5, 0],
            fill: true,
            borderColor: 'rgba(189, 189, 189, 1)',
            backgroundColor: 'rgba(189, 189, 189, .2)',
            pointRadius: 0
          }
        ]
      },
      emptyChartOptions: {
        legend: false,
        title: {
          display: true,
          text: this.title
        },
        scales: {
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 3
              }
            }
          ]
        },
        elements: {
          drawText: {
            text: 'No Data',
            color: 'rgba(189, 189, 189, 1)'
          }
        }
      },
      chart: {
        labels: this.mapCounts().map(() => 'x'),
        datasets: [
          {
            label: 'Global',
            data: this.mapCounts(),
            fill: true,
            borderColor: 'rgba(0, 122, 201, 1)',
            backgroundColor: 'rgba(0, 122, 201, 0.2)',
            pointRadius: 0
          },
          {
            label: this.getAverageName(),
            data: [],
            borderColor: 'rgba(88, 166, 24, 1)',
            backgroundColor: 'rgba(88, 166, 24, 1)'
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: this.title
        },
        datalabels: {
          color: 'blue',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'green'
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              display: false
            },
            {
              scaleLabel: {
                display: true,
                labelString:
                  this.quartiles && this.quartiles.length === 3
                    ? `1st Quartile: ${this.quartiles[0]}    -    2nd Quartile: ${this.quartiles[1]}    -    3rd Quartile: ${this.quartiles[2]}`
                    : ''
              },
              type: 'linear',
              ticks: {
                max: this.maxValue,
                min: this.minValue,
                stepSize: this.stepSize,
                callback: this.formatTicks
              }
            }
          ]
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-1',
              borderColor: 'rgba(88, 166, 24, 1)',
              value: this.average,
              borderDash: [8, 8],
              borderWidth: 2,
              label: {
                content: (!this.average || this.format == 'numeric') ? this.average : `${this.average}%`,
                enabled: true,
                position: 'top',
                backgroundColor: 'rgba(88, 166, 24, 0.8)',
                fontSize: 11,
                xAdjust: -20
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    if (!this.frequencies || this.frequencies.length === 0) {
      this.renderChart(this.emptyChart, this.emptyChartOptions)
    } else {
      this.renderChart(this.chart, this.options)
    }
  },
  methods: {
    mapCounts() {
      // No data
      if (!this.frequencies || this.frequencies.length === 0) {
        return []
      }

      const offset = this.minValue * this.indexFactor
      const numPossibleValues = ((this.maxValue - this.minValue) * this.indexFactor) + 1
      let counts = new Array(numPossibleValues).fill(0)

      for (const frequencyCount of this.frequencies) {
        counts[(frequencyCount.value * this.indexFactor) - offset] = frequencyCount.count
      }

      return counts
    },
    getAverageName() {
      if (!this.average || this.average == 0) {
        return `${this.averageName} (No data)`
      } else {
        return this.averageName
      }
    },
    formatTicks(value, index, values) {
      return this.format == 'numeric' ? value : `${value}%`
    }
  }
}
</script>