<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12"><h2>Select Rows</h2></v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          @change="onStartRowChange"
          :value="dataImportWorkflowStore.startRow"
          type="number"
          label="Starting Row"
          hint="Do not include the header row."
          outlined
          dense
          data-cy="startRowInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          @change="onEndRowChange"
          :value="dataImportWorkflowStore.endRow"
          type="number"
          label="Ending Row"
          outlined
          dense
          data-cy="endRowInput"
        ></v-text-field>
      </v-col>
      <v-col sm="7" md="8" lg="9"></v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          @change="onHeaderRowChange"
          :value="dataImportWorkflowStore.headerRow"
          type="number"
          :disabled="!hasHeaderRow"
          label="Header Row"
          outlined
          dense
          data-cy="headerRowInput"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-checkbox
          @change="onToggleHasHeaderRow"
          :input-value="hasHeaderRow"
          label="Data has a header row."
          data-cy="hasHeaderRowCheckbox"
        />
      </v-col>
      <v-col sm="7" md="8" lg="9"></v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <SpreadsheetViewer mode="rowPicker" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapStores } from 'pinia'

import SpreadsheetViewer from './SpreadsheetViewer'
import { useDataImportWorkflowStore } from '@/stores/dataImportWorkflow'

export default {
  name: 'RowPicker',
  components: {
    SpreadsheetViewer
  },
  data() {
    return {
      hasHeaderRow: false
    }
  },
  computed: {
    ...mapStores(useDataImportWorkflowStore)
  },
  methods: {
    onStartRowChange(rowNumber) {
      this.dataImportWorkflowStore.selectStartRow(rowNumber)
    },
    onEndRowChange(rowNumber) {
      this.dataImportWorkflowStore.selectEndRow(rowNumber)
    },
    onHeaderRowChange(rowNumber) {
      this.dataImportWorkflowStore.selectHeaderRow(rowNumber)
    },
    onToggleHasHeaderRow(value) {
      this.hasHeaderRow = value
      if (value) {
        this.dataImportWorkflowStore.selectHeaderRow(0)
      } else {
        this.dataImportWorkflowStore.selectHeaderRow(null)
      }
    }
  },
  async created() {
    this.hasHeaderRow = this.dataImportWorkflowStore.headerRow !== null
  }
}
</script>