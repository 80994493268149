<template>
  <v-app-bar app color="white" dark id="header">
    <div class="d-flex align-center">
      <v-img
        alt="Nalco Water Logo"
        class="shrink mr-2"
        contain
        src="nalco-logo.webp"
        transition="scale-transition"
        width="200"
      />
    </div>
    <v-spacer></v-spacer>
    <div v-if="!$auth.loading && $auth.isAuthenticated">
      <span class="mr-2 primary--text">Welcome, {{ $auth.user.name }}</span>
      <v-btn class="primary--text" v-on:click="logout" target="_blank" text
        >Logout</v-btn
      >
    </div>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout()
    }
  }
}
</script>
