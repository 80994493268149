<template>
  <v-container fluid id="reporting" ref="reporting">
    <v-row>
      <v-col>
        <v-card>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="export-btn"
                color="accent"
                v-bind="attrs"
                v-on="on"
              >
                Export
              </v-btn>
            </template>
            <v-list>
              <v-list-item 
                v-for="(item, index) in exportOptions" 
                :key="index" 
                link
                @click="item.action"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-select
                  label="Grade"
                  v-model="selectedGradeId"
                  :items="grades"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col v-if="isAdmin">
                <v-select
                  label="Location"
                  v-model="selectedLocationId"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Machine"
                  v-model="selectedMachineId"
                  :items="machines"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Ply"
                  v-model="selectedPly"
                  :items="plies"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row>
              <v-col :cols="4">
                <v-card>
                  <FirstPassRetentionFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('firstPassRetention')"
                    :average="getReportAverage('firstPassRetention')"
                    :quartiles="getReportQuartiles('firstPassRetention')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card>
                  <HeadboxConsistencyFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('headboxConsistency')"
                    :average="getReportAverage('headboxConsistency')"
                    :quartiles="getReportQuartiles('headboxConsistency')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card>
                  <TraySolidsFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('traySolids')"
                    :average="getReportAverage('traySolids')"
                    :quartiles="getReportQuartiles('traySolids')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card>
                  <MachineSpeedFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('machineSpeed')"
                    :average="getReportAverage('machineSpeed')"
                    :quartiles="getReportQuartiles('machineSpeed')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card>
                  <ConditionWeightFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('conditionWeight')"
                    :average="getReportAverage('conditionWeight')"
                    :quartiles="getReportQuartiles('conditionWeight')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card>
                  <LbsTonPolymerFDChart
                    v-if="!loading"
                    :averageName="averageName"
                    :frequencies="getReportFrequencies('lbsTonPolymer')"
                    :average="getReportAverage('lbsTonPolymer')"
                    :quartiles="getReportQuartiles('lbsTonPolymer')"
                  />
                  <div v-else class="text-center progress">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsPDF from 'jspdf'

import FrequencyDistributionChart from '@/components/Reporting/FrequencyDistributionChart'
import FirstPassRetentionFDChart from '@/components/Reporting/FirstPassRetentionFDChart'
import HeadboxConsistencyFDChart from '@/components/Reporting/HeadboxConsistencyFDChart'
import TraySolidsFDChart from '@/components/Reporting/TraySolidsFDChart'
import MachineSpeedFDChart from '@/components/Reporting/MachineSpeedFDChart'
import ConditionWeightFDChart from '@/components/Reporting/ConditionWeightFDChart'
import LbsTonPolymerFDChart from '@/components/Reporting/LbsTonPolymerFDChart'

import LocationService from '@/services/locationService'
import ReportService from '@/services/reportService'
import GradeService from '@/services/paperGradeService'

import config from '@/config'

export default {
  components: {
    FrequencyDistributionChart,
    FirstPassRetentionFDChart,
    HeadboxConsistencyFDChart,
    TraySolidsFDChart,
    MachineSpeedFDChart,
    ConditionWeightFDChart,
    LbsTonPolymerFDChart
  },
  computed: {
    loading() {
      return this.dataLoading || this.gradesLoading || this.locationsLoading
    },
    averageName() {
       if (this.loading) {
        return ''
      }

      const machineName = this.machines.filter(m => m.id == this.selectedMachineId)[0].name

      if (this.showLocationDropdown) {
        const locationName = this.locations.filter(l => l.id == this.selectedLocationId)[0].name

        return `${locationName}/${machineName}`
      } else {
        return machineName
      }
    },
    machines() {
      if (this.loading) {
        return []
      }

      return this.locations.filter(l => l.id == this.selectedLocationId)[0].machines
    },
    roles() {
      const roles = this.$auth.user[`${config.auth0.customClaimNamespace}/roles`]

      if (!roles) {
        return []
      }

      return roles
    },
    isAdmin() {
      return this.roles.includes('Admin')
    }
  },
  data() {
    return {
      reportService: null,
      gradeService: null,
      locationService: null,

      dataLoading: true,
      gradesLoading: true,
      locationsLoading: true,

      reports: {},
      grades: [],
      locations: [],
      plies: [
        {
          text: 'Base',
          value: 'base'
        },
        {
          text: 'Mid',
          value: 'mid'
        },
        {
          text: 'Top',
          value: 'top'
        }
      ],

      selectedGradeId: null,
      selectedLocationId: null,
      selectedMachineId: null,
      selectedPly: 'base',
      exportOptions: [
        { 
          title: 'Excel',
          action: async () => this.exportExcelReport()
        },
        { 
          title: 'PDF',
          action: () => this.exportPdfReport()
        }
      ]
    }
  },
  watch: {
    selectedGradeId: function() {
      this.getData()
    },
    selectedLocationId: function(locationId) {
      this.selectedMachineId = this.locations.filter(l => l.id == locationId)[0].machines[0].id
      this.getData()
    },
    selectedMachineId: function() {
      this.getData()
    },
    selectedPly: function() {
      this.getData()
    }
  },
  methods: {
    async getData() {
      this.dataLoading = true
      if (this.reportService === null) {
        this.reportService = new ReportService()
      }

      this.reports = await this.reportService.getData(
        this.selectedGradeId,
        this.selectedLocationId,
        this.selectedMachineId,
        this.selectedPly
      )      

      this.dataLoading = false
    },
    async getGrades() {
      this.gradesLoading = true
      if (this.gradeService === null) {
        this.gradeService = new GradeService(this.$auth)
      }

      this.grades = await this.gradeService.getPaperGrades()

      this.gradesLoading = false
    },
    async getUserLocation() {
      this.locationsLoading = true
      if (this.locaitonService == null) {
        this.locationService = new LocationService(this.$auth)
      }

      this.locations.push(await this.locationService.getLocation(
        this.$auth.user[`${config.auth0.customClaimNamespace}/location`]
      ))
      this.locationsLoading = false
    },
    async getLocations() {
      this.locationsLoading = true
      if (this.locaitonService == null) {
        this.locationService = new LocationService(this.$auth)
      }

      this.locations = await this.locationService.getLocations()
      this.locationsLoading = false
    },
    getReportFrequencies(metric) {
      return this.reports[metric]
        ? this.reports[metric].frequencies
        : []
    },
    getReportAverage(metric) {
      return this.reports[metric] && this.reports[metric].average
        ? this.reports[metric].average
        : null
    },
    getReportQuartiles(metric) {
      return this.reports[metric]
        ? this.reports[metric].quartiles
        : []
    },
    getReportMaxValue(metric) {
      return this.reports[metric]
        ? this.reports[metric].maxValue
        : 0
    },
    getReportMinValue(metric) {
      return this.reports[metric]
        ? this.reports[metric].minValue
        : 0
    },
    getReportIndexFactor(metric) {
      return this.reports[metric]
        ? this.reports[metric].indexFactor
        : 0
    },
    async exportExcelReport() {
      if (Object.keys(this.reports).length === 0) {
        alert('No data to export!')
      } else {
        await this.reportService.exportReport(this.grades.filter(g => g.id == this.selectedGradeId)[0], this.selectedLocationId)
      }
    },
    exportPdfReport() {
      const reports = document.querySelectorAll('canvas')
      const pageHeight = this.$refs.reporting.clientHeight
      const pageWidth = this.$refs.reporting.clientWidth

      let pdfCanvas = document.createElement('canvas')
      pdfCanvas.id = 'pdf-canvas'
      pdfCanvas.height = pageHeight
      pdfCanvas.width = pageWidth

      let pdfContext = pdfCanvas.getContext('2d')
      let pdfContextX = 0
      let pdfContextY = 0
      let buffer = 0
      let index = 0

      for (let report of reports) {
        const reportHeight = report.clientHeight
        const reportWidth = report.clientWidth

        pdfContext.drawImage(report, pdfContextX, pdfContextY, reportWidth, reportHeight)
        pdfContextX += reportWidth + buffer

        index++
        if (index % 3 === 0) {
          pdfContextX = 0
          pdfContextY += reportHeight + 100
        }
      }

      const pdf = new jsPDF('landscape', 'pt', [pageHeight, pageWidth])
      pdf.addImage(pdfCanvas, 'PNG', 0, 0, pageWidth, pageHeight)

      const selectedGradeName = this.grades.filter(g => g.id == this.selectedGradeId)[0].name
      pdf.save(`${selectedGradeName}.pdf`)
    }
  },
  async created() {
    if (!this.$auth.loading) {
      await this.getGrades()

      if (this.isAdmin) {
        await this.getLocations()
      } else {
        await this.getUserLocation()
      }

      this.selectedGradeId = this.grades[0].id
      this.selectedLocationId = this.locations[0].id
      this.selectedMachineId = this.locations.filter(l => l.id == this.selectedLocationId)[0].machines[0].id

      await this.getData()
    }
  }
}
</script>

<style scoped>
.progress {
  padding-bottom: 50px;
}

.report-navigation {
  padding: 10px;
  text-align: center;
}

.export-btn {
  margin: 10px;
  float: right;
}
</style>