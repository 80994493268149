import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const instrumentationKey = process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY || '9522517d-e4f5-4186-8224-03294311ac16'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
    correlationHeaderExcludedDomains: ['*.us.auth0.com'] // Auth0 does not support the tracking headers
  }
})

if (process.env.NODE_ENV != 'development') {
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

export default {
  install(Vue) {
    Vue.prototype.$analytics = appInsights
  }
}
