<template>
  <div>
    <div v-if="isActivated">Some kind of welcome message here.</div>
    <div v-else>
      <h2 class="title">
        Your account has not been activated yet. Please try again later.
      </h2>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { getInstance as getUserService } from '@/services/userService'

export default {
  name: 'Home',
  data() {
    return {
      userService: null,
      currentUser: {}
    }
  },
  computed: {
    isActivated() {
      const roles = this.$auth.user[`${config.auth0.customClaimNamespace}/roles`]

      if (!roles) {
        return false
      }

      else {
        return roles.includes('Worker') || roles.includes('Admin')
      }
    }
  },
  methods: {
    async getCurrentUser() {
      if (!this.userService) {
        this.userService = getUserService()
      }

      return await this.userService.getCurrentUser()
    },
    async createNewUser() {
      if (!this.userService) {
        this.userService = getUserService()
      }

      await this.userService.createNewUser()
    }
  },
  async created() {
    // Create a new user if the current user doesn't already exist
    try {
      await this.getCurrentUser()
    } catch (e) {
      if (e.response.status === 404) {
        await this.createNewUser()
      } else {
        console.error(e)
      }
    }
  }
}
</script>
