import { defineStore } from 'pinia'
import { ref } from 'vue'
import XLSX from 'xlsx'

import { getInstance as getDataService } from '@/services/dataService'

export const useDataImportWorkflowStore = defineStore('dataImportWorkflow', () => {
  let dataService = null

  const locationId = ref(null)
  const machineId = ref(null)
  const ply = ref('base')

  const filename = ref(null)
  const spreadsheet = ref(null)
  const worksheet = ref(null)
  const worksheetName = ref(null)
  
  const previousStartRow = ref(0)
  const previousEndRow = ref(0)
  const startRow = ref(0)
  const endRow = ref(0)
  const headerRow = ref(null)

  const gradeMap = ref(null)

  const headboxConsistencyColumn = {
    columnName: ref(null),
    columnFormat: ref('percent')
  }
  const traySolidsColumn = {
    columnName: ref(null),
    columnFormat: ref('percent')
  }
  const firstPassRetentionColumn = {
    columnName: ref(null),
    columnFormat: ref('percent')
  }
  const gradeColumn = {
    columnName: ref(null),
  }
  const machineSpeedColumn = {
    columnName: ref(null),
  }
  const conditionWeightColumn = {
    columnName: ref(null),
  }
  const lbsTonPolymerColumn = {
    columnName: ref(null),
  }
  const lbsTonPolymerConstant = ref(null)
  const dateColumn = {
    columnName: ref(null),
  }

  async function setLocation(id) {
    locationId.value = id
    machineId.value = null
  }

  async function setMachine(id) {
    machineId.value = id
  }

  async function setPly(value) {
    ply.value = value
  }

  async function getImportInfoForNewSpreadsheet() {
    // Need to refactor this so that every call that needs to hit the api doesn't first have to go grab the data service
    if (!dataService) {
      dataService = await getDataService()
    }

    const importInfoResponse = await dataService.getImportInfoByFilename(locationId.value, machineId.value, filename.value)

    if (importInfoResponse.importInfo) {
      worksheetName.value = importInfoResponse.importInfo.worksheet
      if (worksheetName.value) {
        selectWorksheet(worksheetName.value)
      }

      startRow.value = importInfoResponse.importInfo.startRow
      endRow.value = importInfoResponse.importInfo.endRow
      headerRow.value = importInfoResponse.importInfo.headerRow ?? null

      previousStartRow.value = importInfoResponse.importInfo.startRow
      previousEndRow.value = importInfoResponse.importInfo.endRow

      lbsTonPolymerConstant.value = importInfoResponse.importInfo.lbsTonPolymerConstant ?? null

      headboxConsistencyColumn.columnName = importInfoResponse.importInfo.columnMap?.headboxConsistency?.name ?? null
      headboxConsistencyColumn.columnFormat = importInfoResponse.importInfo.columnMap?.headboxConsistency?.format ?? null
      traySolidsColumn.columnName = importInfoResponse.importInfo.columnMap?.traySolids?.name ?? null
      traySolidsColumn.columnFormat = importInfoResponse.importInfo.columnMap?.traySolids?.format ?? null
      firstPassRetentionColumn.columnName = importInfoResponse.importInfo.columnMap?.firstPassRetention?.name ?? null
      firstPassRetentionColumn.columnFormat = importInfoResponse.importInfo.columnMap?.firstPassRetention?.format ?? null
      gradeColumn.columnName = importInfoResponse.importInfo.columnMap?.grade?.name ?? null
      machineSpeedColumn.columnName = importInfoResponse.importInfo.columnMap?.machineSpeed?.name ?? null
      conditionWeightColumn.columnName = importInfoResponse.importInfo.columnMap?.conditionWeight?.name ?? null
      lbsTonPolymerColumn.columnName = importInfoResponse.importInfo.columnMap?.lbsTonPolymer?.name ?? null
      dateColumn.columnName = importInfoResponse.importInfo.columnMap?.date?.name ?? null
    }
  }

  function clearSpreadsheet() {
    filename.value = null
    spreadsheet.value = null
    worksheet.value = null
    worksheetName.value = null

    startRow.value = 0
    endRow.value = 0
    headerRow.value = null

    gradeMap.value = null

    headboxConsistencyColumn.columnName = null
    headboxConsistencyColumn.columnFormat = 'percent'
    traySolidsColumn.columnName = null
    traySolidsColumn.columnFormat = 'percent'
    firstPassRetentionColumn.columnName = null
    firstPassRetentionColumn.columnFormat = 'percent'
    gradeColumn.columnName = null
    machineSpeedColumn.columnName = null
    conditionWeightColumn.columnName = null
    lbsTonPolymerColumn.columnName = null
    lbsTonPolymerConstant.value = null
    dateColumn.columnName = null
  }

  async function loadNewSpreadsheet(file, locationId, machineId) {
    const reader = new FileReader()

    reader.onload = (e) => {
      let data = e.target.result
      spreadsheet.value = XLSX.read(data, {
        type: 'binary'
      })
    }

    reader.onerror = function (error) {
      console.error(error)
      alert('Something went wrong wile trying to read the file. If this problem persists please contact your administrator.')
    }

    filename.value = file.name
    reader.readAsBinaryString(file)

    await getImportInfoForNewSpreadsheet(locationId, machineId)
  }

  function selectWorksheet(name) {
    worksheetName.value = name
    const rawWorksheet = spreadsheet.value.Sheets[worksheetName.value]

    const sheetToJsonConfig = {
      blankrows: false,
      defval: '',
      raw: false,
      header: 'A'
    }

    worksheet.value = XLSX.utils.sheet_to_json(
      rawWorksheet,
      sheetToJsonConfig
    )
  }

  function selectStartRow(rowNumber) {
    startRow.value = rowNumber
  }

  function selectEndRow(rowNumber) {
    endRow.value = rowNumber
  }

  function selectHeaderRow(rowNumber) {
    headerRow.value = rowNumber
  }

  function setLbsTonPolymer(value) {
    lbsTonPolymerConstant.value = value
  }

  function setHeadboxConsistencyColumnName(value) {
    headboxConsistencyColumn.columnName = value
  }

  function setHeadboxConsistencyColumnFormat(value) {
    headboxConsistencyColumn.columnFormat = value
  }

  function setTraySolidsColumnName(value) {
    traySolidsColumn.columnName = value
  }

  function setTraySolidsColumnFormat(value) {
    traySolidsColumn.columnFormat = value
  }

  function setFirstPassRetentionColumnName(value) {
    firstPassRetentionColumn.columnName = value
  }

  function setFirstPassRetentionColumnFormat(value) {
    firstPassRetentionColumn.columnFormat = value
  }

  function setGradeColumnName(value) {
    gradeColumn.columnName = value
  }

  function setMachineSpeedColumnName(value) {
    machineSpeedColumn.columnName = value
  }

  function setConditionWeightColumnName(value) {
    conditionWeightColumn.columnName = value
  }

  function setLbsTonPolymerColumnName(value) {
    lbsTonPolymerColumn.columnName = value
  }

  function setDateColumnName(value) {
    dateColumn.columnName = value
  }

  function setGradeMap(value) {
    gradeMap.value = value
  }

  return { 
    locationId,
    machineId,
    ply,
    filename,
    spreadsheet,
    worksheetName,
    worksheet, 
    previousStartRow,
    previousEndRow,
    startRow,
    endRow,
    headerRow,
    headboxConsistencyColumn,
    traySolidsColumn,
    firstPassRetentionColumn,
    gradeColumn,
    machineSpeedColumn,
    conditionWeightColumn,
    lbsTonPolymerColumn,
    lbsTonPolymerConstant,
    dateColumn,
    gradeMap,
    setLocation,
    setMachine,
    setPly,
    clearSpreadsheet,
    loadNewSpreadsheet,
    selectWorksheet,
    selectStartRow,
    selectEndRow,
    selectHeaderRow,
    setLbsTonPolymer,
    setHeadboxConsistencyColumnName,
    setHeadboxConsistencyColumnFormat,
    setTraySolidsColumnName,
    setTraySolidsColumnFormat,
    setFirstPassRetentionColumnName,
    setFirstPassRetentionColumnFormat,
    setGradeColumnName,
    setMachineSpeedColumnName,
    setConditionWeightColumnName,
    setLbsTonPolymerColumnName,
    setDateColumnName,
    setGradeMap
  }
})